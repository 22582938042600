import { TaxonomyType } from "./types";
import image1 from "images/populate/populate1.svg";
import image2 from "images/populate/populate2.svg";
import image3 from "images/populate/populate3.svg";
import image4 from "images/populate/populate4.svg";

// import program1 from "images/program/program-image-1.png";
// import program2 from "images/program/program-image-2.png";
// import program3 from "images/program/program-image-3.png";

import program1 from "images/program/program1.svg";
import program2 from "images/program/program2.svg";
import program3 from "images/program/program3.svg";

import news1 from "images/news/news1.png";
import news2 from "images/news/news2.png";

export const slider1: any[] = [
  {
    id: "1",
    href: "/",
    title: "TP. Hồ Chí Minh",
    taxonomy: "category",
    // count: 17288,
    image_url:
      "https://3go.vn/wp-content/uploads/2023/06/vuihe25-1024x1024.jpg",
    desc: "200+ xe",
  },
  {
    id: "2",
    href: "/",
    title: "Hà Nội",
    desc: "200+ xe",
    taxonomy: "category",
    count: 2118,
    image_url:
      "https://3go.vn/wp-content/uploads/2023/06/AWO-PRO-5-1024x1024.jpg",
  },
  {
    id: "3",
    href: "/",
    title: "Nha Trang",
    desc: "200+ xe",
    taxonomy: "category",
    count: 36612,
    image_url:
      "https://3go.vn/wp-content/uploads/2023/12/Uu-dai-giam-20-1024x1024.jpg",
  },
  {
    id: "3",
    href: "/",
    title: "Nha Trang",
    desc: "200+ xe",
    taxonomy: "category",
    count: 36612,
    image_url:
      "https://3go.vn/wp-content/uploads/2023/10/bb76b8c43ecce992b0dd-1024x1024.jpg",
  },
];
export const slider2: TaxonomyType[] = [
  {
    id: "1",
    href: "/",
    name: "TP. Hồ Chí Minh",
    taxonomy: "category",
    // count: 17288,
    thumbnail: image1,
    desc: "200+ xe",
  },
  {
    id: "2",
    href: "/",
    name: "Hà Nội",
    desc: "200+ xe",
    taxonomy: "category",
    count: 2118,
    thumbnail: image2,
  },
  {
    id: "3",
    href: "/",
    name: "Nha Trang",
    desc: "200+ xe",
    taxonomy: "category",
    count: 36612,
    thumbnail: image3,
  },
  {
    id: "4",
    href: "/",
    name: "Đà Nẵng",
    desc: "200+ xe",
    taxonomy: "category",
    count: 36612,
    thumbnail: image4,
  },
];
export const sliderNews: TaxonomyType[] = [
  {
    id: "1",
    href: "/",
    name: "Các Hình Thức Dịch Vụ Cho Thuê Xe Tự Lái",
    taxonomy: "category",
    // count: 17288,
    thumbnail:
      "https://3go.vn/wp-content/uploads/2024/01/Thue-xe-7-cho-tu-lai-TPHCM-1-1.png",
    date: "19/06/2024",
    desc: "Thuê xe tự lái là dịch vụ được nhiều khách hàng lựa chọn bởi sự tiện lợi, đáp ứng đầy đủ nhu cầu đi lại",
  },
  {
    id: "1",
    href: "/",
    name: "kinh nghiệm thuê xe hơi ngày tết",
    taxonomy: "category",
    // count: 17288,
    thumbnail:
      "https://3go.vn/wp-content/uploads/2024/01/thue-xe-ngay-tet-768x512.jpg",
    date: "19/06/2024",
    desc: "Tết Nguyên Đán là thời điểm nhu cầu thuê xe về quê hay đi du lịch tăng cao. Điều này sẽ khiến bạn khó tránh",
  },
  {
    id: "1",
    href: "/",
    name: "Các Hình Thức Dịch Vụ Cho Thuê Xe Tự Lái",
    taxonomy: "category",
    // count: 17288,
    thumbnail:
      "https://3go.vn/wp-content/uploads/2024/01/Thue-xe-7-cho-tu-lai-TPHCM-1-1.png",
    date: "19/06/2024",
    desc: "Thuê xe tự lái là dịch vụ được nhiều khách hàng lựa chọn bởi sự tiện lợi, đáp ứng đầy đủ nhu cầu đi lại",
  },
  {
    id: "1",
    href: "/",
    name: "kinh nghiệm thuê xe hơi ngày tết",
    taxonomy: "category",
    // count: 17288,
    thumbnail:
      "https://3go.vn/wp-content/uploads/2024/01/thue-xe-ngay-tet-768x512.jpg",
    date: "19/06/2024",
    desc: "Tết Nguyên Đán là thời điểm nhu cầu thuê xe về quê hay đi du lịch tăng cao. Điều này sẽ khiến bạn khó tránh",
  },
];

export const DADVITE_DATA = [
  {
    question: "Điều kiện và thủ tục để đặt xe trên 3GO ?",
    title: [
      "Bạn tải ứng dụng 3GO, đăng kí tài khoản và xác thực bằng SĐT/Email. Sau đó cập nhật thông tin trên ứng dụng 3GO yêu cầu.",
      "Bạn chỉ cần chụp hình:",
    ],
    answers: [
      "Giấy phép lái xe hợp lệ.",
      "Căn cước công dân/ hộ chiếu còn hiệu lực.",
    ],
  },
  {
    question: "Thuê xe trên ứng dụng 3GO có cần đặt cọc không?",
    answers: [
      "3GO áp dụng số tiền đặt cọc đảm bảo 2.000.000 đồng/ lần đặt xe. Số tiền sẽ được hoàn lại cho bạn sau khi kết thúc chuyến đi.",
      "Để dễ dàng thanh toán, số tiền đặt cọc đảm bảo sẽ thanh toán cùng phí đặt xe.",
    ],
  },
  {
    question: "Làm sao để thuê xe trên ứng dụng 3GO?",
    title: ["Đặt xe 3GO chỉ với 3 bước:"],
    answers: [
      "Chọn vị trí, thời gian bạn muốn thuê xe và nhấn “TÌM XE”.",
      "Lựa chọn xe bạn muốn thuê. Áp dụng mã khuyến mãi và thanh toán.",
      "Đội ngũ 3GO tiến hành xác minh và thông báo qua mail hoặc SĐT cho bạn.",
    ],
    note: "Nhằm đảm bảo quá trình chuẩn bị xe được tốt nhất. Quí khách vui lòng đặt xe trước 2 giờ so với thời gian nhận xe.",
  },
  {
    question:
      "Thời gian thuê xe và quãng đường giới hạn được 3GO quy định thế nào?",
    answers: [
      "3GO quy định thời gian thuê tối thiểu là 8 giờ và không giới hạn thời gian thuê xe. Nếu vượt quá thời gian thuê xe, phí gia hạn sẽ được tính dựa trên đơn giá mỗi giờ chiếc xe bạn đã thuê.",
      "Nhằm đảm bảo an toàn cho cho chuyến đi của bạn: 3GO quy định quãng đường giới hạn là 500 km/ngày. Phí vượt trội được tính 3.000đ – 9.000đ/km.",
    ],
  },
  {
    question: "Phí thuê xe được tính thế nào?",
    answers: [
      "Chi phí thuê xe cơ sở được tính: “Đơn giá của xe” x “số giờ thuê” (phí thuê đã bao gồm các loại thuế)",
      "Chi phí này được thay đổi linh hoạt dựa trên thời gian bắt đầu đặt xe, xe được lựa chọn và khoảng thời gian thuê xe.",
      "Nếu bạn áp dụng mã khuyến mãi. Ưu đãi sẽ được trừ trực tiếp vào chí phí thuê",
      "Ngoài ra trong trường hợp phát sinh các chi phí khác như: nhiên liệu, vệ sinh xe, phí vượt trội,… sẽ được trừ vào số tiền đặt cọc đảm bảo trước đó.",
    ],
  },
  {
    question: "Bạn có thể hủy chuyến 3GO đã được không?",
    answers: [
      "Bạn có thể hoàn toàn hủy chuyến đi miễn phí trước 24 giờ so với thời điểm bắt đầu khởi hành.",
      "Nếu bạn tiến hành hủy chuyến đi trong vòng 24 giờ kể từ thời điểm bắt đầu khởi hành. Bạn sẽ mất 80% phí thuê xe",
      "Hủy chuyến sau thời gian khởi hành - Bạn không được hoàn lại phí thuê xe.",
    ],
  },
  {
    question: "Bạn có thể thay đổi lịch đặt xe hay không?",
    answers: [
      "3GO cho phép bạn thay đổi lịch đặt xe trước 12 giờ so với thời gian bắt đầu khởi hành.",
      "Nếu quá thời gian quy định bạn phải thực hiện hủy chuyến và đặt lại chuyến xe khác.",
      "Vì vậy, bạn hãy lên lịch trình hợp lí để khởi hành đúng thời gian bạn nhé!",
    ],
  },
  {
    question: "Nếu xảy ra hư hỏng, va chạm xe cần làm gì?",
    answers: [
      "Đội ngũ 3GO luôn luôn hỗ trợ bạn 24/24 trong suốt chuyến đi của bạn.",
      "Khi xảy ra sự cố, bạn cần giữ nguyên hiện trường và liên hệ ngay 3GO qua hotline: 1900.9999.25 hoặc 0932.96.88.96",
      "Mọi chi phí phát sinh được quy định trong chính sách.",
    ],
  },
  {
    question:
      "3GO có thể sử dụng xe di chuyển giữa các thành phố không/ Tôi có thể trả xe ở địa điểm khác hay không?",
    answers: [
      "Bạn hoàn toàn có thể trải nghiệm 3GO để đến bất cứ đâu trên lãnh thổ Việt Nam. Tuy nhiên, bạn cần trả xe về địa điểm đã nhận sau khi kết thúc chuyến đi để tránh phát sinh thêm chi phí.",
    ],
  },
  {
    question: "Liên hệ với 3GO?",
    answers: [
      "Đội ngũ 3GO hỗ trợ bạn 24/24 bao gồm cả ngày lễ.",
      "Quí khách hàng và đối tác liên hệ 3GO qua hotline: 1900.9999.25 hoặc 0932.96.88.96",
    ],
  },
  {
    question:
      "Thuế Thu nhập cá nhân (Thuế TNCN) chủ xe được nộp thuế hay giải quyết như thế nào?",
    title: ["3GO sẽ giữ lại 10% thuế TNCN/mỗi chuyến xe:"],
    answers: [
      "3GO sẽ nộp thuế cho Bên B khi Bên B có tổng doanh thu trong năm dương lịch trên 100 triệu đồng.",
      "3GO sẽ hoàn lại cho Bên B khi Bên B có tổng doanh thu trong năm dương lịch dưới 100 triệu đồng.",
    ],
  },
];
