import { atom } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

export type companyId = string | number | null;

export const companyIdAtom = atom<companyId>({
  key: "COMPANY_ID",
  default: process.env.REACT_APP_ID_COMPANY,
  effects_UNSTABLE,
});
