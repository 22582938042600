import { atom, selector } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

export interface UserInfoProps {
  user: UserProps | null;
  token: string | null;
  fcmToken: string | null;
}

export interface UserProps {
  id: number;
  is_superadmin: number;
  lang_id: number | null;
  zalo_id: string | null;
  partner_user_id: string | null;
  partner_user_type: string | null;
  sapo_id: string | null;
  follow_oa: number | string;
  rank_point: number;
  affiliate_parent_id: string | null;
  affiliate_package_id: string | null;
  approval_status_affiliate: string | null;
  user_type: string;
  is_walkin_customer: number;
  login_enabled: number;
  name: string | null;
  gender: string;
  email: string | null;
  password_zalo: string | null;
  phone: string | null;
  profile_image: string | null;
  profile_image_zalo_url: string | null;
  address: string | null;
  date_of_birth?: string | null;
  shipping_address: string | null;
  email_verification_code: string | null;
  status: string;
  reset_code: string | null;
  timezone: string;
  date_format: string;
  date_picker_format: string;
  time_format: string;
  tax_number: string | null;
  created_by: string | null;
  created_at: string;
  updated_at: string;
  affiliate_created_at: string | null;
  user_status_id: string | null;
  cccd_front_image: string | null;
  cccd_back_image: string | null;
  driving_license_front_image: string | null;
  face_near: string | null;
  face_far: string | null;
  ekyc_status: string;
  member: {
    total_current_amount: number;
    rank: string;
    next_rank: string;
    max_spent_amount: number;
    amount_to_point: number;
    converted_amount: number;
    maximum_discount_order: number;
    max_discount: number;
    xid_rank: string;
    xid_next_rank: string;
    amount_to_point_next_rank: number;
  } | null;
  xid: string;
  profile_image_url: string;
  x_warehouse_id: string | null;
  x_role_id: string | null;
  x_company_id: string;
  count_order_affiliate: number;
  total_income_affiliate: number;
}

export type TokenProps = string | null;

export const userAtom = atom<UserInfoProps>({
  key: "USER",
  default: {
    user: null,
    token: null,
    fcmToken: null,
  },
  effects_UNSTABLE,
});

export const userInfoSelector = selector<UserProps | null>({
  key: "USER_INFO",
  get: ({ get }) => {
    const userInfo = get(userAtom);
    return userInfo?.user ?? null;
  },
});

export const userTokenSelector = selector<TokenProps>({
  key: "USER_TOKEN",
  get: ({ get }) => {
    const userInfo = get(userAtom);
    return userInfo?.token ?? null;
  },
});

export const userfcmTokenSelector = selector<TokenProps>({
  key: "USER_FCM_TOKEN",
  get: ({ get }) => {
    const userInfo = get(userAtom);
    return userInfo?.fcmToken ?? null;
  },
});
