import React, { FC, useCallback, useEffect, useRef } from "react";
import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import { useNavigate } from "react-router-dom";
// import { Autoplay } from "@glidejs/glide/dist/glide.modular.esm";

import Heading from "components/Heading/Heading";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import CardNews from "components/CardCNews/CardNews";
import CarCard from "components/CarCard/CarCard";
import SkeletonCardNews from "components/CardCNews/SkeletonCardNews";

import NextPrev from "shared/NextPrev/NextPrev";
import { useFilter } from "atom/filter/useFilter";
import BannerItem from "components/BannerItem";
import SkeletonCarCard from "components/CarCard/SkeletonCarCard";
import SkeletonBannerCar from "components/BannerItem/SkeletonBannerCar";
import SkeletonCategoryCard from "components/CardCategory4/SkeletonCategoryCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css"; // Import Swiper styles if not already

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: any[];
  categoryCardType?:
    | "card3"
    | "card4"
    | "card5"
    | "cardNews"
    | "cardCar"
    | "cardBanner";
  itemPerRow?: number;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
  isShowContent?: boolean;
  autoplay?: false | number;
  loading: boolean;
  canClicked?: boolean;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  heading = "Heading of sections",
  subHeading,
  className = "",
  itemClassName = "",
  data,
  itemPerRow = 5,
  categoryCardType = "card3",
  sliderStyle = "style1",
  uniqueClassName,
  isShowContent,
  autoplay = 4000,
  loading = true,
  canClicked = false,
}) => {
  const UNIQUE_CLASS =
    "SectionSliderNewCategories__" + uniqueClassName + useNcId();
  const sliderRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { setFilter } = useFilter();
  const swiperRef = useRef<any | null>(null);

  // useEffect(() => {
  //   if (sliderRef.current) {
  //     try {
  //       const slides = sliderRef.current.querySelectorAll(".glide__slide");

  //       const glide = new Glide(sliderRef.current, {
  //         perView: itemPerRow,
  //         rewind: true,
  //         animationDuration: 1000,
  //         gap: 32,
  //         rewindDuration: 1000,
  //         autoplay:   ?? false, // Temporarily set autoplay to false
  //         bound: true,
  //         breakpoints: {
  //           1280: {
  //             perView: itemPerRow < 3 ? itemPerRow : itemPerRow - 1,
  //           },
  //           1024: {
  //             gap: 20,
  //             perView: itemPerRow < 3 ? itemPerRow : itemPerRow - 1,
  //           },
  //           768: {
  //             gap: 20,
  //             perView: itemPerRow < 3 ? itemPerRow : itemPerRow - 2,
  //           },
  //           640: {
  //             gap: 20,
  //             perView: itemPerRow <= 3 ? itemPerRow : itemPerRow - 3,
  //           },
  //           500: {
  //             gap: 20,
  //             perView: 1.3,
  //           },
  //         },
  //       });

  //       glide.on("run.before", () => {
  //         const currentIndex = glide.index;
  //         const autoplay =
  //           slides[currentIndex]?.getAttribute("data-glide-autoplay") ||
  //           glide.settings.autoplay;
  //         glide.update({ autoplay: parseInt(autoplay as string, 10) || false });
  //       });

  //       glide.mount({ Autoplay });
  //     } catch (error) {
  //       console.error("Error initializing Glide:", error);
  //     }
  //   } else {
  //     console.error("Slider ref is not set");
  //   }
  // }, [itemPerRow, data, autoplay]);

  const handleView = ({ id, title }: { id: number; title: string }) => {
    if (!canClicked) return;
    setFilter((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        province: {
          title,
          id,
        },
      },
    }));
    navigate("/search", {
      state: {
        province: {
          title,
          id,
        },
      },
    });
  };

  const renderCard = useCallback(
    (item: any, index: number) => {
      switch (categoryCardType) {
        case "card3":
          return <CardCategory3 taxonomy={item} />;
        case "card4":
          return loading ? (
            <SkeletonCategoryCard />
          ) : (
            <CardCategory4
              onClick={() =>
                canClicked &&
                handleView({
                  id: item.id,
                  title: item.title,
                })
              }
              key={index}
              taxonomy={item}
              isShowContent={isShowContent}
              className={itemClassName}
            />
          );
        case "card5":
          return <CardCategory5 taxonomy={item} />;
        case "cardNews":
          return loading ? <SkeletonCardNews /> : <CardNews data={item} />;
        case "cardCar":
          return loading ? <SkeletonCarCard /> : <CarCard data={item} />;
        case "cardBanner":
          return loading ? <SkeletonBannerCar /> : <BannerItem data={item} />;
        default:
          return <CardCategory3 taxonomy={item} />;
      }
    },
    [data]
  );

  const items = !loading || data?.length ? data : [...Array(4)];

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === "style1"}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>
        {/* <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides mx-auto py-5">
            {items?.map((item, index) => (
              <li key={index} className={`glide__slide`}>
                {renderCard(item, index)}
              </li>
            ))}
          </ul>
        </div> */}

        <Swiper
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          spaceBetween={32}
          // slidesPerView={itemPerRow}
          centeredSlides={itemPerRow % 2 === 0}
          autoplay={{
            delay: autoplay || 5000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            1280: {
              slidesPerView: itemPerRow < 3 ? itemPerRow : itemPerRow - 1,
              spaceBetween: 32,
            },
            1024: {
              slidesPerView: itemPerRow < 3 ? itemPerRow : itemPerRow - 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: itemPerRow < 3 ? itemPerRow : itemPerRow - 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: itemPerRow <= 3 ? itemPerRow : itemPerRow - 3,
              spaceBetween: 20,
            },
            500: {
              slidesPerView: 1.3,
              spaceBetween: 20,
            },
          }}
          loop={true}
          modules={[Autoplay]}
        >
          {items?.map((item, index) => (
            <SwiperSlide key={index}>{renderCard(item, index)}</SwiperSlide>
          ))}
        </Swiper>

        {sliderStyle === "style2" && (
          <NextPrev
            className={`justify-center mt-14 ${data?.length < 3 && "hidden"}`}
            onClickNext={() => swiperRef.current?.slideNext()}
            onClickPrev={() => swiperRef.current?.slidePrev()}
          />
        )}
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
