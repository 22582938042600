import React, { FC } from "react";
import Card13 from "./CardNewsMedium";
import { DataProps } from "atom/news/news";
import CardNews from "./CardNews";
import SkeletonCarNews from "./Skeleton/SkeletonCarNews";
import SkeletonCarNews3 from "./Skeleton/SkeletonCarNews3";

export interface SectionMagazine5Props {
  news: DataProps[];
  loading?: boolean;
}

const SectionMagazine5: FC<SectionMagazine5Props> = ({ news, loading }) => {
  const isLoading = !news?.length && loading;
  return (
    <div className="nc-SectionMagazine5">
      <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
        {isLoading ? <SkeletonCarNews /> : <CardNews news={news[0]} />}
        <div className="grid gap-6 md:gap-8">
          {isLoading
            ? [...Array(3)].map((_, i) => <SkeletonCarNews3 key={i} />)
            : news
                .filter((_, i) => i < 5 && i > 0)
                .map((item, index) => <Card13 key={index} news={item} />)}
        </div>
      </div>
    </div>
  );
};

export default SectionMagazine5;
