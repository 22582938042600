//@ts-nocheck

import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Tab } from "@headlessui/react";

import CarCard from "components/CarCard/CarCard";
import SkeletonCarCard from "components/CarCard/SkeletonCarCard";
import ModalFilterMobile from "components/ModalFilterMobile";
import { useCar } from "atom/car/useCar";
import { useFilter } from "atom/filter/useFilter";
import { categories } from "data/search";
import SearchSidebar from "./SearchSidebar";
import Pagination from "shared/Pagination/Pagination";
import NotFoundCar from "components/NotFoundCar";
import { useWarehouse } from "atom/warehouse/useWarehouse";
import { useAsyncEffect } from "ahooks";

export interface SearchPageProps {
  className?: string;
}

const pageSize = 8;

const SearchPage: FC<SearchPageProps> = ({ className = "" }) => {
  const {
    requestGetCarsByPage,
    requestGetCarsNearByPage,
    requestGetCarsFlashsaleByPage,
    requestGetCarsNewManufactureByPage,
  } = useCar();
  const { requestFilterCar, getQuery, filter } = useFilter();
  const { warehouseId } = useWarehouse();
  const { state } = useLocation();

  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("all");
  const [data, setData] = useState([]);

  useEffect(() => window.scrollTo(0, 0), [data]);

  useAsyncEffect(async () => {
    if (!warehouseId) return;
    switch (currentTab) {
      case "all":
        await requestFilterCar
          .runAsync(getQuery(filter))
          .then((res) => setData(res?.data));
        break;
      case "near":
        await requestGetCarsNearByPage
          .runAsync({ offset: pageSize * (currentPage - 1) })
          .then((res) => setData(res?.data));
        break;
      case "flashsale":
        await requestGetCarsFlashsaleByPage
          .runAsync(pageSize * (currentPage - 1))
          .then((res) => setData(res?.data));
        break;
      case "newmanufacture":
        await requestGetCarsNewManufactureByPage
          .runAsync(pageSize * (currentPage - 1))
          .then((res) => setData(res?.data));
        break;
      default:
        await requestGetCarsByPage
          .runAsync(pageSize * (currentPage - 1))
          .then((res) => setData(res?.data));
        break;
    }
  }, [currentPage, currentTab, warehouseId]);

  useAsyncEffect(async () => {
    // if (!warehouseId) return;
    // setCurrentTab("all");
    // if (state?.province?.id) {
    //   await requestFilterCar
    //     .runAsync(
    //       getQuery({
    //         ...filter,
    //         date: {
    //           startDate: filter?.date?.startDate,
    //           endDate: filter?.date?.endDate,
    //         },
    //         address: filter.address,
    //       })
    //     )
    //     .then((res) => setData(res?.data));
    //   return;
    // } else if (JSON.stringify(filter)) {
    //   requestFilterCar
    //     .runAsync(
    //       getQuery({
    //         ...filter,
    //         date: {
    //           startDate: filter?.date?.startDate,
    //           endDate: filter?.date?.endDate,
    //         },
    //       })
    //     )
    //     .then((res) => setData(res?.data));
    //   return;
    // } else if (!data?.legnth && warehouseId) {
    //   await requestFilterCar.runAsync(getQuery(filter)).then((res) => {
    //     setData(res?.data);
    //   });
    // }
  }, [warehouseId]);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeTab = (key) => {
    setCurrentPage(1);
    setCurrentTab(key);
  };

  const renderPagination = (meta) => {
    return;
    if (meta?.paging?.total <= pageSize || isFilter || !data.length) return;
    return (
      <div className="flex mt-16 justify-center items-center">
        <Pagination
          totalPage={Math.ceil(meta?.paging?.total / pageSize)}
          currentPage={currentPage}
          handleChangePage={(page) => handleChangePage(page)}
        />
      </div>
    );
  };

  const renderData = (loading = true) => {
    if (loading) {
      return Array.from({ length: 8 }, (_, index) => (
        <SkeletonCarCard key={index} />
      ));
    }

    if (!data || data.length === 0) {
      return <NotFoundCar />;
    }

    // const displayData = isFilter ? data : data.slice(0, 8);

    return data.map((car) => <CarCard key={car.xid} data={car} />);
  };

  const renderSection1 = () => {
    return (
      <div className="">
        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto no-scrollbar">
              <div className="flex lg:hidden  px-2">
                <ModalFilterMobile
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  setCars={setData}
                />
              </div>
              {categories.map((item) => (
                <Tab key={item.key} as={Fragment}>
                  <button
                    onClick={() => handleChangeTab(item.key)}
                    className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3  rounded-full focus:outline-none ${
                      currentTab === item?.key
                        ? "bg-colorPrimary text-white "
                        : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                  >
                    {item?.label}
                  </button>
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-5 md:gap-7 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                  {renderData(
                    requestGetCarsByPage.loading ||
                      requestFilterCar.loading ||
                      !warehouseId
                  )}
                </div>
                {renderPagination(
                  requestFilterCar?.data?.meta ||
                    requestGetCarsByPage.data?.meta
                )}
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-5 md:gap-7 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                  {renderData(requestGetCarsNearByPage.loading)}
                </div>
                {renderPagination(requestGetCarsNearByPage.data?.meta)}
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-5 md:gap-7 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                  {renderData(requestGetCarsFlashsaleByPage?.loading)}
                </div>
                {renderPagination(requestGetCarsFlashsaleByPage.data?.meta)}
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-5 md:gap-7 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                  {renderData(requestGetCarsNewManufactureByPage.loading)}
                </div>
                {renderPagination(
                  requestGetCarsNewManufactureByPage.data?.meta
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>3GO | Tìm kiếm</title>
        <meta
          name="description"
          content="Tìm kiếm các dịch vụ cho thuê xe tốt nhất với 3GO. Khám phá nhiều mẫu xe và dịch vụ phù hợp với nhu cầu của bạn."
        />
        <meta
          name="keywords"
          content="tìm kiếm xe, dịch vụ cho thuê xe, tìm xe, 3GO"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="UTF-8" />
        <link rel="canonical" href="https://3go.ui.aimini.vn/search" />

        <meta property="og:title" content="3GO | Tìm kiếm" />
        <meta
          property="og:description"
          content="Tìm kiếm các dịch vụ cho thuê xe tốt nhất với 3GO. Khám phá nhiều mẫu xe và dịch vụ phù hợp với nhu cầu của bạn."
        />
        <meta
          property="og:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <meta property="og:url" content="https://3go.ui.aimini.vn/search" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="3GO | Tìm kiếm" />
        <meta
          name="twitter:description"
          content="Tìm kiếm các dịch vụ cho thuê xe tốt nhất với 3GO. Khám phá nhiều mẫu xe và dịch vụ phù hợp với nhu cầu của bạn."
        />
        <meta
          name="twitter:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              name: "Search | 3GO",
              description:
                "Tìm kiếm các dịch vụ cho thuê xe tốt nhất với 3GO. Khám phá nhiều mẫu xe và dịch vụ phù hợp với nhu cầu của bạn.",
              url: "https://3go.ui.aimini.vn/search",
              mainEntity: {
                "@type": "SearchAction",
                target: {
                  "@type": "EntryPoint",
                  urlTemplate:
                    "https://3go.ui.aimini.vn/search?q={search_term_string}",
                },
                "query-input": "required name=search_term_string",
              },
            }),
          }}
        />
      </Helmet>

      <main className="containers mx-5 md:mx-10 mt-5 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="hidden lg:block flex-grow mb-24 lg:mb-0 relative">
          <div className="overflow">
            <SearchSidebar
              tabsSelected={currentTab}
              setTabsSelected={setCurrentTab}
              setCars={setData}
              setIsFilter={setIsFilter}
            />
          </div>
        </div>

        <div className="w-full lg:w-3/4  space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
        </div>
      </main>
    </div>
  );
};

export default SearchPage;
