import { useAsyncEffect, useRequest } from "ahooks";
import { useRecoilState } from "recoil";
import {
  getCategoryNewsApi,
  getListNewsApi,
  getNewsByCategoryApi,
  getNewsBySlugApi,
} from "services/api/newsApi";
import {
  NewsProps,
  newsAtom,
  categoryNewsAtom,
  CategoryNewsProps,
  listNewsAtom,
} from "./news";
import { useCompanyId } from "atom/company/useCompany";

export const useNews = () => {
  const { companyId } = useCompanyId();

  const [news, setNews] = useRecoilState<NewsProps>(newsAtom);
  const [listNews, setListNews] = useRecoilState<NewsProps[]>(listNewsAtom);
  const [categoryNews, setCategoryNews] =
    useRecoilState<CategoryNewsProps[]>(categoryNewsAtom);

  const requestGetCategoryNews = useRequest(() => getCategoryNewsApi(), {
    cacheKey: "get-category-news",
    manual: true,
    onSuccess: (res: any) => setCategoryNews(res?.data),
  });

  const requestGetNewsByCategory = useRequest(
    (categoryId, offset, limit) =>
      getNewsByCategoryApi(categoryId, offset, limit),
    {
      cacheKey: "get-category-news",
      manual: true,
    }
  );

  const requestGetNews = useRequest(
    (offset, limit = 6) => getListNewsApi(offset, limit),
    {
      cacheKey: "get-news",
      manual: true,
      onError: (err) => console.log("API GET NEWS", err),
    }
  );
  const requestGetNewsBySlug = useRequest(
    (slug, offset = 0, limit = 1000) => getNewsBySlugApi(offset, limit, slug),
    {
      cacheKey: "get-news-by-slug",
      manual: true,
      onError: (err) => console.log("API GET NEWS BY SLUG", err),
    }
  );

  useAsyncEffect(async () => {
    if (categoryNews.length < 1) requestGetCategoryNews.runAsync();
  }, [companyId]);

  useAsyncEffect(async () => {
    if (!news.isCalledApi)
      requestGetNews.runAsync(news.offset, news.limit).then((res: any) => {
        setNews({
          ...news,
          data: res?.data,
          isCalledApi: true,
          noMore: res?.data.length >= res.meta.paging.total,
        });
      });
  }, [companyId]);

  return {
    news,
    categoryNews,
    requestGetNews,
    requestGetCategoryNews,
    requestGetNewsByCategory,
    requestGetNewsBySlug,
    setCategoryNews,
    setNews,
  };
};
