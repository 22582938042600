import { useState } from "react";
import { Popover } from "@headlessui/react";
import { FC } from "react";
import ButtonSubmit from "./ButtonSubmit";
import { GuestsObject } from "./type";
import { useFilter } from "atom/filter/useFilter";
import { useNavigate } from "react-router-dom";
import { showCustomToast } from "shared/toast";

export interface SearchButtonProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
}

const SearchButton: FC<SearchButtonProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref,
  hasButtonSubmit = true,
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(2);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(1);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(1);

  const { filter } = useFilter();
  const navigate = useNavigate();

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
  };

  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

  const handleSearch = () => {
    if (JSON.stringify(filter?.address?.province).includes("null"))
      return showCustomToast({ type: 2, message: "Bạn chưa chọn địa điểm" });
    if (JSON.stringify(filter?.date).includes("null"))
      return showCustomToast({ type: 2, message: "Bạn chưa chọn thời gian" });
    navigate("/search", {
      state: {
        province: {
          id: filter?.address?.province?.id,
        },
      },
    });
  };

  return (
    <Popover
      className={`flex relative items-center justify-end mr-4  ${className}`}
    >
      <ButtonSubmit onClick={handleSearch} />
    </Popover>
  );
};

export default SearchButton;
