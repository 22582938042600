import { atom, selector } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

export interface ProductCategoryProps {
  company_id: string | number;
  created_at: string | null;
  image: string | null;
  image_url: string | null;
  name: string | null;
  slug: string | null;
  updated_at: string | null;
  x_parent_id: string | null;
  xid: string;
}

export interface ServiceCategoryProps {
  image: string | null;
  image_url: string | null;
  name: string | null;
  slug: string | null;
  xid: string;
  childs: object[] | [];
}

export interface SubProductCategoryProps {
  company_id: string | number;
  created_at: string | null;
  image: string | null;
  image_url: string | null;
  name: string | null;
  slug: string | null;
  subCategories?: object[];
  updated_at: string | null;
  x_parent_id: string | null;
  xid: string;
}

export const productCategoryAtom = atom<ProductCategoryProps[]>({
  key: `PRODUCT-CATEGORY`,
  default: [],
  effects_UNSTABLE,
});

export const subCategorySelector = selector<SubProductCategoryProps[]>({
  key: "SUB_CATEGORY",
  get: ({ get }) => {
    const category = get(productCategoryAtom);
    if (category.length > 0) {
      const categoryTree = createCategoryTree(category);
      return categoryTree;
    }
    return [];
  },
});

export const serviceCategoryAtom = atom<ServiceCategoryProps[]>({
  key: `SERVICE-CATEGORY`,
  default: [],
  effects_UNSTABLE,
});

//Function build tree category from list all product categories
const createCategoryTree = (data, parentId = null) => {
  const tree: any = [];
  data.forEach((item) => {
    if (item.x_parent_id === parentId) {
      const subCategories = createCategoryTree(data, item.xid);
      let newItem = { ...item };
      if (subCategories.length > 0) {
        newItem = { ...item, subCategories };
      }
      tree.push(newItem);
    }
  });
  return tree;
};
