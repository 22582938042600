import { CHANGE_PASSWORD, UPDATE_PROFILE } from "./fieldName";
import * as yup from "yup";
import { REGEX } from "./regex";

export const UU = UPDATE_PROFILE;
export const CP = CHANGE_PASSWORD;

export const schemaUpdateProfile = yup.object().shape({
  [UU.EMAIL]: yup
    .string()
    .matches(RegExp(REGEX.EMAIL), "Địa chỉ email không hợp lệ")
    .required("Vui lòng nhập email."),
  // [UU.PHONE]: yup
  //   .string()
  //   .matches(RegExp(REGEX.NUMBER_PHONE), "Số điện thoại không hợp lệ")
  //   .required("Vui lòng nhập số điện thoại."),
  [UU.NAME]: yup.string().required("Vui lòng nhập tên."),
  [UU.ADDRESS]: yup.string().required("Vui lòng nhập địa chỉ."),
});

export const schemaChangePassword = yup.object().shape({
  [CP.OLD_PASSWORD]: yup.string().required("Vui lòng nhập mật khẩu cũ."),
  // [CP.NEW_PASSWORD]: yup
  //   .string()
  //   .matches(
  //     RegExp(REGEX.PASSWORD),
  //     "Mật khẩu phải có ít nhất 8 ký tự, bao gồm ít nhất một chữ cái viết thường, một chữ cái viết hoa, một chữ số và một ký tự đặc biệt."
  //   )
  //   .required("Vui lòng nhập mật khẩu mới.")
  //   .min(8, "Mật yêu cầu tối thiểu 8 kí tự"),
  [CP.NEW_PASSWORD]: yup
    .string()
    .matches(
      RegExp(REGEX.PASSWORD),
      "Mật khẩu phải có ít nhất 8 ký tự, bao gồm ít nhất một chữ cái viết thường, một chữ cái viết hoa, một chữ số và một ký tự đặc biệt."
    )
    .required("Vui lòng nhập mật khẩu mới.")
    .min(8, "Mật yêu cầu tối thiểu 8 kí tự"),
});
