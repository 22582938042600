import React, { FC, useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import WidgetPosts from "./WidgetPosts";
import Card3 from "./Card3";
import { DataProps } from "atom/news/news";
import SkeletonCarNews3 from "./Skeleton/SkeletonCarNews3";
import { useNews } from "atom/news/useNews";

export interface SectionLatestPostsProps {
  className?: string;
  postCardName?: "card3";
  news: any;
  loading?: boolean;
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  postCardName = "card3",
  className = "",
  news,
  loading,
}) => {
  const [listNews, setListNews] = useState([]);
  const [offset, setOffset] = useState(news?.offset);
  const { requestGetNews } = useNews();

  useEffect(() => {
    setListNews(news?.data);
    setOffset(news?.offset);
  }, [news]);

  const handleLoadMore = async () => {
    await requestGetNews.runAsync(offset + 1, news?.limit).then((res: any) => {
      setListNews((prev) => [...prev, ...res.data]);
      console.log(res);
    });
  };

  const renderCard = (newsItem: DataProps) => {
    switch (postCardName) {
      case "card3":
        return <Card3 key={newsItem.xid} className="" news={newsItem} />;

      default:
        return null;
    }
  };

  const isLoading = !news?.data?.length && !loading;

  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row justify-between">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
          <Heading>Bài viết mới nhất 🎈</Heading>
          <div className={`grid gap-6 md:gap-8 grid-cols-1`}>
            {isLoading
              ? [...Array(2)].map((_, i) => <SkeletonCarNews3 key={i} />)
              : listNews.map((newsItem) => renderCard(newsItem))}
          </div>
          <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />
            <ButtonPrimary
              onClick={handleLoadMore}
              loading={requestGetNews.loading}
            >
              Xem thêm
            </ButtonPrimary>
          </div>
        </div>
        <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 justify-end">
          {/* <WidgetTags /> */}
          {/* <WidgetCategories /> */}
          <WidgetPosts />
        </div>
      </div>
    </div>
  );
};

export default SectionLatestPosts;
