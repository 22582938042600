import CommonLayout from "containers/AccountPage/CommonLayout";
import React from "react";
import { Outlet } from "react-router-dom";

const LayoutInformation = () => {
  return (
    <CommonLayout>
      <Outlet />
    </CommonLayout>
  );
};

export default LayoutInformation;
