import { atom } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

export interface AddressProps {
  name: string;
  email: string;
  phone: string;
  address: string;
  shipping_address: string;
  city: string;
  state: string;
  country: string;
  xid: string;
}

export const addressAtom = atom<AddressProps[]>({
  key: "ADDRESS",
  default: [],
  effects_UNSTABLE,
});

//Địa chỉ mặc định trong danh sách địa chỉ
export const defaultAddressAtom = atom<AddressProps | null>({
  key: "DEFAULT_ADDRESS",
  default: null,
  effects_UNSTABLE,
});

//địa chỉ giao hàng khi đặt hàng
export const shippingAddressAtom = atom<AddressProps | null>({
  key: "SHIPPING_ADDRESS",
  default: null,
  effects_UNSTABLE,
});

export const provinceAtom = atom<any | null>({
  key: "PROVINCE_DATA",
  default: null,
  effects_UNSTABLE,
});
export const districtAtom = atom<any | null>({
  key: "DISTRICT_DATA",
  default: null,
  effects_UNSTABLE,
});

export const currentAddressAtom = atom<{
  lat: number;
  lng: number;
  address: any;
} | null>({
  key: "CURRENT_ADDRESS",
  default: null,
  effects_UNSTABLE,
});
