import React, { FC, ReactNode, useState } from "react";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";
import homeBanner from "../../images/home-banner.png";
import NcPlayIcon from "shared/NcPlayIcon/NcPlayIcon";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Stays" | "Experiences" | "Cars" | "Flights";
  currentTab: SearchTab;
  rightImage?: string;
}

const videoID = process.env.REACT_APP_YT_VIDEO_ID;

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage,
}) => {
  const [isPlay, setIsPlay] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePage"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={homeBanner}
        alt="img"
        className="w-full h-full min-h-[300px] object-cover"
        loading="lazy"
      />
      <p className="absolute font-bold text-2xl text-[#EFF5FA] text-center md:w-[576px] capitalize bottom-5 md:top-10 md:right-32 px-5 h-fit">
        Trải nghiệm sự khác biệt từ hơn 8000 xe gia đình đời mới khắp Việt Nam
      </p>

      {isPlay ? (
        <div className="w-full top-0">
          <iframe
            src={`https://www.youtube.com/embed/${videoID}?autoplay=1&controls=0&rel=0&showinfo=0&fs=0`}
            title={"3go"}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            className="w-full h-full absolute top-0"
            // onLoad={() => console.log("Iframe loaded and video is ready")}
          ></iframe>
        </div>
      ) : (
        <div
          onClick={() => setIsPlay(true)}
          className="cursor-pointer absolute inset-0 flex items-center justify-center -top-20"
        >
          <NcPlayIcon />
        </div>
      )}

      <div
        className={`hidden lg:flex w-full mx-auto justify-center items-center transition-opacity duration-[1000ms] ${
          isPlay && !isHovered ? "opacity-0" : "opacity-100"
        }`}
      >
        <div className="z-20 lg:-mt-40 xl:-mt-56 w-full">
          <HeroSearchForm currentPage={currentPage} currentTab={currentTab} />
        </div>
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
