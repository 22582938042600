import { BannerDataType } from "atom/banner/banner";
import React, { FC, memo } from "react";
import LazyImage from "shared/LazyImage/LazyImage";

export interface BannerItemProps {
  className?: string;
  data: BannerDataType;
  isShowContent?: boolean;
  onClick?: () => void;
}

const BannerItem: FC<BannerItemProps> = ({
  className = "",
  data,
  isShowContent = false,
  onClick,
}) => {
  return (
    <div className={` flex flex-col ${className}`} onClick={onClick}>
      <LazyImage
        alt=""
        src={data?.url}
        className="object-cover w-full h-full rounded-xl"
      />
    </div>
  );
};

export default memo(BannerItem);
