import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface IModalCancel {
  isOpen: {
    report: boolean;
    cancel: boolean;
    active: boolean;
    PolicyCancel: {
      type: 1 | 2;
      isShow: boolean;
    };
  };
  setIsOpen: React.Dispatch<
    React.SetStateAction<{
      report: boolean;
      cancel: boolean;
      active: boolean;
      PolicyCancel: {
        type: 1 | 2;
        isShow: boolean;
      };
    }>
  >;
  setIsShowModalPolicyCancel?: React.Dispatch<
    React.SetStateAction<{
      type: number;
      isShow: boolean;
    }>
  >;
}

export default function ModalCancel({
  isOpen,
  setIsOpen,
  setIsShowModalPolicyCancel,
}: IModalCancel) {
  const handleCancel = () => {};
  return (
    <>
      <Transition appear show={isOpen.cancel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() =>
            setIsOpen((prev) => ({
              ...prev,
              cancel: false,
            }))
          }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-colorPrimary">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-normal leading-6 text-red-500 text-center dark:text-neutral-200 cursor-pointer"
                  >
                    Bạn muốn hủy chuyến?
                  </Dialog.Title>
                  <Dialog.Description
                    className={"mt-5 px-2 text-textSecondary"}
                  >
                    Bạn chắc chắn muốn huỷ chuyến? Vui lòng tham khảo{" "}
                    <span
                      className="text-colorPrimary underline font-semibold cursor-pointer"
                      onClick={() =>
                        setIsShowModalPolicyCancel({
                          type: 2,
                          isShow: true,
                        })
                      }
                    >
                      Chính sách huỷ chuyến
                    </span>{" "}
                    của 3GO
                  </Dialog.Description>

                  <div className="flex flex-col justify-center mt-6 space-y-3">
                    <ButtonPrimary>Hủy chuyến</ButtonPrimary>

                    <button
                      onClick={() =>
                        setIsOpen((prev) => ({
                          ...prev,
                          cancel: false,
                        }))
                      }
                    >
                      Quay lại
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
