import { ApiUtils } from ".";
import { PATH } from "./path";

export const addRatingApi = async (data, token) => {
  return await ApiUtils.post(PATH.add_rating, data, { token: token });
};

export const getRatingApi = async (query) => {
  return await ApiUtils.request(PATH.get_rating + query);
};

export const uploadFileApi = async (form: any, token: string) => {
  const data = {
    image: form.get("image"),
    folder: form.get("folder"),
  };

  return await ApiUtils.post(PATH.upload_file, data, {
    token: token,
    isUpload: true,
  });
};
export const uploadFileBase64Api = async (form: any, token: string) => {
  const data = {
    image: form.get("image"),
    folder: form.get("folder"),
  };

  return await ApiUtils.post(PATH.upload_file, data, {
    token: token,
    isUpload: true,
  });
};
