import { useMount, useRequest } from "ahooks";
import { useRecoilState } from "recoil";
import { placeAtom, PlaceProps } from "./place";
import { getHotPlacesApi } from "services/api/addressApi";

export const usePlace = () => {
  const [place, setPlace] = useRecoilState<PlaceProps[]>(placeAtom);

  const requestGetHotPlaces = useRequest(() => getHotPlacesApi(), {
    cacheKey: "get-hot-places",
    manual: true,
    onSuccess: (res: any) => setPlace(res),
    onError: (err: any) => console.log("API HOT PLACES", err),
  });

  useMount(() => {
    if (place.length < 1) {
      requestGetHotPlaces.runAsync();
    }
  });

  return {
    place,
    requestGetHotPlaces,
    setPlace,
  };
};
