import { useRecoilState } from "recoil";
import { useAsyncEffect, useMount, useRequest } from "ahooks";
import { useUser } from "atom/user/useUser";
import { globalLoading } from "shared/loading";
import { showCustomToast } from "shared/toast";
import {
  addAddressApi,
  deleteAddressApi,
  editAddressApi,
  getAddressesApi,
  getDistrictsApi,
  getProvincesApi,
} from "services/api/addressApi";
import {
  AddressProps,
  addressAtom,
  currentAddressAtom,
  defaultAddressAtom,
  districtAtom,
  provinceAtom,
  shippingAddressAtom,
} from "./address";
import {
  getDataFromStorage,
  setDataToStorage,
  StorageKey,
} from "services/storage";
import { useEffect, useState } from "react";

export const useAddress = () => {
  const [address, setAddress] = useRecoilState<AddressProps[]>(addressAtom);
  const [defaultAddress, setDefaultAddress] =
    useRecoilState<AddressProps | null>(defaultAddressAtom);
  const [shippingAddress, setShippingAddress] =
    useRecoilState<AddressProps | null>(shippingAddressAtom);
  const { token } = useUser();
  const [provinceData, setProvinceData] = useRecoilState<any>(provinceAtom);
  const [districtData, setDistrictData] = useRecoilState<any>(districtAtom);
  const [currentAddress, setCurrentAddress] = useRecoilState<{
    lat: number;
    lng: number;
    address?: any;
  }>(currentAddressAtom);
  const [provinceSelected, setProvinceSelected] = useState(null);

  const requestGetAddresses = useRequest(
    () => getAddressesApi(token as string),
    {
      manual: true,
      onSuccess: (res: any) => {
        setAddress(res.data);
        // setDefaultAddress(res?.data[0]);
        setShippingAddress(res?.data[0]);
      },
      onError: (err: any) => console.log("API ALL ADDRESS", err),
    }
  );

  const requestAddAddress = useRequest(
    (data) => addAddressApi(data, token as string),
    {
      cacheKey: "add-address",
      manual: true,
      onError: (err: any) => console.log("API ADD ADDRESS", err.message),
    }
  );

  const requestEditAddress = useRequest(
    (addressId, data) => editAddressApi(addressId, data, token as string),
    {
      cacheKey: "edit-address",
      manual: true,
      onError: (err: any) => console.log("API EDIT ADDRESS", err.message),
    }
  );

  const requestDeleteAddress = useRequest(
    (addressId) => deleteAddressApi(addressId, token as string),
    {
      cacheKey: "delete-address",
      manual: true,
      onError: (err: any) => console.log("API DELETE ADDRESS", err.message),
    }
  );

  const requestGetDefaultAddress = useRequest(
    () => getDataFromStorage(StorageKey.DefaultAddress),
    {
      cacheKey: "get-default-address",
      manual: true,
      onSuccess: (res: any) => {
        setDefaultAddress(res ?? address[0]);
        setShippingAddress(res ?? address[0]);
      },
      onError: (err: any) => console.log("GET DEFAULT ADDRESS", err.message),
    }
  );

  const requestGetProvinces = useRequest(() => getProvincesApi(), {
    manual: true,
    onSuccess: (res: any) => setProvinceData(res?.data),
    onError: (err: any) => console.log("API GET PROVINCES", err),
  });

  const requestGetDistricts = useRequest((xid) => getDistrictsApi(xid), {
    manual: true,
    onSuccess: (res: any) => setDistrictData(res?.data),
    onError: (err: any) => console.log("API GET DISTRICTS", err),
  });

  const addAddress = (data: any, onSuccess?: () => any) => {
    globalLoading.show();
    requestAddAddress
      .runAsync(data)
      .then((res: any) => {
        let newAddress = { ...data, xid: res.data.xid };
        setAddress([...address, newAddress]);
        globalLoading.hide();
        showCustomToast({ type: 1, message: "Thêm địa chỉ thành công" });
        //cập nhật lại địa chỉ mới được thêm là địa chỉ được chọn
        setShippingAddress(newAddress);
        !!onSuccess && onSuccess();
      })
      .catch((err) => {
        globalLoading.hide();
        showCustomToast({ type: 0, message: "Thêm địa chỉ thất bại" });
      });
  };

  const editAddress = (addressId: string, data: any, onSuccess?: () => any) => {
    globalLoading.show();
    requestEditAddress
      .runAsync(addressId, data)
      .then((res: any) => {
        let newAddressList = address.map((x) =>
          x.xid !== addressId ? x : { ...data, xid: addressId }
        );
        setAddress(newAddressList);
        globalLoading.hide();
        showCustomToast({ type: 1, message: "Sửa địa chỉ thành công" });
        !!onSuccess && onSuccess();
      })
      .catch((err) => {
        globalLoading.hide();
        showCustomToast({ type: 0, message: "Sửa địa chỉ thất bại" });
      });
  };

  //set default address
  const handleDefaultAddress = (address) => {
    setDefaultAddress(address);
    setDataToStorage(StorageKey.DefaultAddress, address);
  };

  useMount(() => {
    if (address.length < 1 && token) {
      requestGetAddresses.runAsync();
    }
    if (address.length == 1 && token) {
      setShippingAddress(address[0]);
    }

    if (!provinceData?.length) requestGetProvinces.runAsync();
  });

  useEffect(() => {
    if (!provinceData?.length) return;
    if (provinceSelected) requestGetDistricts.runAsync(provinceSelected);
  }, [provinceSelected]);

  return {
    address,
    provinceData,
    provinceSelected,
    currentAddress,
    districtData,
    defaultAddress,
    shippingAddress,
    requestGetAddresses,
    requestAddAddress,
    requestDeleteAddress,
    requestGetProvinces,
    addAddress,
    editAddress,
    setAddress,
    setCurrentAddress,
    setDefaultAddress,
    setProvinceSelected,
    setShippingAddress,
    handleDefaultAddress,
  };
};
