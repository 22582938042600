import { atom } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

export interface SettingProps {
  hideBottomBar: boolean;
  imageGalleryModal: { show: boolean; images: any[] };
  imageGalleryPreview: {
    show: boolean;
    index: number;
    images: any[];
  };
}

export const settingAtom = atom({
  key: "SETTING",
  default: {
    hideBottomBar: false,
    imageGalleryModal: {
      show: false,
      image: [],
      index: 0,
    },
    imageGalleryPreview: {
      show: false,
      index: 0,
      images: [],
    },
  },
  effects_UNSTABLE,
});
