import React from "react";
import { Helmet } from "react-helmet";

import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { useNews } from "atom/news/useNews";

const BlogPage: React.FC = () => {
  const { news, requestGetNews } = useNews();

  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>3GO | Tin tức</title>
        <meta
          name="description"
          content="Cập nhật tin tức mới nhất từ 3GO về dịch vụ cho thuê xe, khuyến mãi, và nhiều thông tin hữu ích khác."
        />
        <meta name="keywords" content="tin tức, 3GO, cho thuê xe, khuyến mãi" />

        <meta property="og:title" content="3GO || Tin tức" />
        <meta
          property="og:description"
          content="Cập nhật tin tức mới nhất từ 3GO về dịch vụ cho thuê xe, khuyến mãi, và nhiều thông tin hữu ích khác."
        />
        <meta
          property="og:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <meta property="og:url" content={window.location.href + "/news"} />
        <meta property="og:type" content="article" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="3GO || Tin tức" />
        <meta
          name="twitter:description"
          content="Cập nhật tin tức mới nhất từ 3GO về dịch vụ cho thuê xe, khuyến mãi, và nhiều thông tin hữu ích khác."
        />
        <meta
          name="twitter:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      {/* ======= START CONTAINER ============= */}
      <div className="container relative">
        {/* === SECTION 1 === */}
        <div className="pt-12 pb-16 lg:pb-28">
          <SectionMagazine5 news={news.data} loading={requestGetNews.loading} />
        </div>

        {/* === SECTION 1 === */}
        {/* <SectionAds /> */}

        {/* === SECTION 8 === */}
        <SectionLatestPosts
          news={news}
          loading={requestGetNews.loading}
          className="py-16"
        />

        {/* === SECTION 1 === */}
        <SectionSubscribe2 className="pb-16 lg:pb-28" />
      </div>
    </div>
  );
};

export default BlogPage;
