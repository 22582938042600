import { effects_UNSTABLE } from "hooks/useRecoilLogger";
import { atom } from "recoil";

export interface WishlistProps {
  data: any;
  isCalledApi: boolean;
}

export const wishlistAtom = atom<WishlistProps>({
  key: "WISHLIST",
  default: {
    data: {},
    isCalledApi: false,
  },
  effects_UNSTABLE,
});

export const wishlistNewsAtom = atom<WishlistProps>({
  key: "WISHLIST-NEWS",
  default: {
    data: {},
    isCalledApi: false,
  },
  effects_UNSTABLE,
});
