//@ts-nocheck

import React, {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StayDataType } from "data/types";
import { Tab } from "@headlessui/react";
import Pagination from "shared/Pagination/Pagination";
import SkeletonCarCard from "components/CarCard/SkeletonCarCard";
import { useCar } from "atom/car/useCar";
import CarCard from "components/CarCard/CarCard";
import NotFoundCar from "components/NotFoundCar";
import { useWarehouse } from "atom/warehouse/useWarehouse";
import Button from "shared/Button/Button";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  data?: any[];
  loading?: boolean;
}

const pageSize = 8;

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  heading,
  tabs = [
    {
      key: "all",
      label: "Tất cả",
    },
    {
      key: "near",
      label: "Xe gần đây",
    },
    {
      key: "flashsale",
      label: "Đang ưu đãi",
    },
    {
      key: "newmanufacture",
      label: "Xe đời mới",
    },
  ],
  loading,
}) => {
  const {
    car,
    requestGetCarsNewManufactureByPage,
    requestGetCarsFlashsaleByPage,
    requestGetCarsNearByPage,
    requestGetCarsByPage,
  } = useCar();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("all");
  const [data, setData] = useState(car?.data);
  const { warehouseId } = useWarehouse();

  const navigate = useNavigate();

  // gets data car on first
  useEffect(() => {
    if (car?.data?.length) {
      setData(car.data);
    }
  }, [car]);

  const handleChangeTab = (key) => {
    setCurrentPage(1);
    setCurrentTab(key);
  };
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const totalPage = useMemo(
    () => Math.ceil(car?.meta?.paging?.total / pageSize),
    [car?.meta?.paging?.total]
  );

  useEffect(() => {
    if (!warehouseId) return;
    switch (currentTab) {
      case "all":
        requestGetCarsByPage
          .runAsync(pageSize * (currentPage - 1))
          .then((res) => setData(res.data));
        break;
      case "near":
        requestGetCarsNearByPage
          .runAsync({ offset: pageSize * (currentPage - 1) })
          .then((res) => setData(res.data));
        break;
      case "flashsale":
        requestGetCarsFlashsaleByPage
          .runAsync(pageSize * (currentPage - 1))
          .then((res) => setData(res.data));
        break;
      case "newmanufacture":
        requestGetCarsNewManufactureByPage
          .runAsync(pageSize * (currentPage - 1))
          .then((res) => setData(res.data));
        break;
      default:
        requestGetCarsByPage
          .runAsync(pageSize * (currentPage - 1))
          .then((res) => setData(res.data));
        break;
    }
  }, [currentPage, currentTab, warehouseId]);

  const renderPagination = (meta) => {
    return;
    if (meta?.paging?.total < pageSize) return;
    return (
      <div className="flex mt-16 justify-center items-center">
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          handleChangePage={(page) => handleChangePage(page)}
        />
      </div>
    );
  };

  const renderData = (loading = true) => {
    if (loading)
      return [...Array(8)].map((_, index) => <SkeletonCarCard key={index} />);
    if (!data?.length) return <NotFoundCar />;
    return data?.map((car) => <CarCard key={car.xid} data={car} />);
  };

  return (
    <div className="">
      <Tab.Group>
        <div className="flex flex-row items-center justify-between">
          <Tab.List className="flex space-x-1 py-2 overflow-x-auto hiddenScrollbar">
            {tabs.map((item, index) => (
              <Tab key={index} as={Fragment}>
                {({ selected }) => (
                  <button
                    onClick={() => handleChangeTab(item.key)}
                    className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                      selected
                        ? "bg-colorPrimary text-white "
                        : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                  >
                    {item.label}
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Button
            className="flex flex-row items-center space-x-2"
            onClick={() => navigate("/listing-car")}
          >
            <span className="w-full  text-neutral-500">Xem thêm</span>
            <ArrowRightIcon className="size-6 text-neutral-500" />
          </Button>
        </div>
        <Tab.Panels>
          <Tab.Panel className="">
            <div className="mt-8 grid grid-cols-1 gap-5 md:gap-7 sm:grid-cols-2 xl:grid-cols-4">
              {renderData(requestGetCarsByPage.loading || !warehouseId)}
            </div>
            {renderPagination(requestGetCarsByPage.data?.meta)}
          </Tab.Panel>
          <Tab.Panel className="">
            <div className="mt-8 grid grid-cols-1 gap-5 md:gap-7 sm:grid-cols-2 xl:grid-cols-4">
              {renderData(requestGetCarsNearByPage.loading)}
            </div>
            {renderPagination(requestGetCarsNearByPage.data?.meta)}
          </Tab.Panel>
          <Tab.Panel className="">
            <div className="mt-8 grid grid-cols-1 gap-5 md:gap-7 sm:grid-cols-2 xl:grid-cols-4">
              {renderData(requestGetCarsFlashsaleByPage?.loading)}
            </div>
            {renderPagination(requestGetCarsFlashsaleByPage.data?.meta)}
          </Tab.Panel>
          <Tab.Panel className="">
            <div className="mt-8 grid grid-cols-1 gap-5 md:gap-7 sm:grid-cols-2 xl:grid-cols-4">
              {renderData(requestGetCarsNewManufactureByPage.loading)}
            </div>
            {renderPagination(requestGetCarsNewManufactureByPage.data?.meta)}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default SectionGridFeaturePlaces;
