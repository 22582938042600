import React from "react";

interface TitltProps {
  children: React.ReactNode;
  className?: string;
}

const Title: React.FC<TitltProps> = ({ children, className }) => {
  return (
    <p className={`text-2xl text-colorPrimary ${className}`}>{children}</p>
  );
};

export default Title;
