import React, { FC, useEffect, useMemo, useState } from "react";
import Pagination from "shared/Pagination/Pagination";
import Title from "components/Title";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";
import { useCar } from "atom/car/useCar";

export interface SectionGridFilterCardProps {
  className?: string;
}

const pageSize = 8;
const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const { requestGetCarsByPage, car } = useCar();
  const [currentPage, setCurrentPage] = useState(1);
  const [cars, setCars] = useState(car?.data);

  // const totalPage = useMemo(
  //   () => Math.ceil(car?.meta?.paging?.total / pageSize),
  //   [car?.meta?.paging?.total]
  // );

  useEffect(() => {
    setCurrentPage(1);
    if (car?.data?.length) setCars(car?.data);
  }, [car?.data]);

  // const handleChangePage = async (page: number) => {
  //   await requestGetCarsByPage.runAsync((page - currentPage) * pageSize);
  //   setCurrentPage(page);
  // };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Title
        children="Xe cho thuê"
        className="text-[38px] font-extrabold text-center"
      />
      <SectionGridFeaturePlaces
        data={cars}
        loading={requestGetCarsByPage.loading}
      />
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          handleChangePage={handleChangePage}
        />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
