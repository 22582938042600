import React, { useEffect } from "react";
import MyRouter from "routers/index";
import { ToastContainer } from "react-toastify";
import { getCurrentAddress } from "helpers/address/getCurrentLocation";
import { useAddress } from "atom/address/useAddress";
import { useAsyncEffect } from "ahooks";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "react-toastify/dist/ReactToastify.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { GlobalLoading } from "shared/loading";

function App() {
  const { currentAddress, setCurrentAddress } = useAddress();
  useAsyncEffect(async () => {
    if (window.location.href.includes("view-mode")) return;
    if (!currentAddress && "geolocation" in navigator) {
      const data: any = await getCurrentAddress();
      setCurrentAddress(data);
    }
  }, [navigator]);

  // ban token login tu google va dong window
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const googleToken = urlParams.get("token");

    if (googleToken) {
      window.opener.postMessage("googleToken=" + googleToken, "*");
      window.close();
    }
  }, []);

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <GlobalLoading />
      <ToastContainer />
      <MyRouter />
    </div>
  );
}

export default App;
