import { ApiUtils } from "./index";
import { PATH } from "./path";

const COMPANY_ID = process.env.REACT_APP_ID_COMPANY;

export const getAddressesApi = async (token: string) => {
  return await ApiUtils.request(PATH.get_addresses, { token: token });
};

export const addAddressApi = async (data: any, token: string) => {
  return await ApiUtils.post(PATH.add_address, data, { token: token });
};

export const editAddressApi = async (
  addressId: string,
  data: any,
  token: string
) => {
  return await ApiUtils.put(PATH.edit_address + addressId, data, {
    token: token,
  });
};

export const deleteAddressApi = async (addressId: string, token: string) => {
  return await ApiUtils.remove(PATH.edit_address + addressId, { token: token });
};

export const getProvincesApi = async () => {
  return await ApiUtils.request(
    PATH.get_places + `?filters=type%20eq%20"province"&limit=100`
  );
};

export const getDistrictsApi = async (provinceId: number) => {
  return await ApiUtils.request(
    PATH.get_places + `?filters=province_id%20eq%20"${provinceId}"&limit=200`
  );
};

export const getDistrict = async (token: string, idProvince: string) => {
  return await ApiUtils.request(`${PATH.get_district}/${idProvince}`, {
    token: token,
  });
};

export const getWard = async (token: string, idDistrict: string) => {
  return await ApiUtils.request(`${PATH.get_ward}/${idDistrict}`, {
    token: token,
  });
};

export const getShipping = async (token: string, data: any) => {
  return await ApiUtils.post(PATH.get_shipping, data, { token: token });
};

export const getProvince = async (token: string) => {
  return await ApiUtils.request(PATH.get_province, { token: token });
};

export const getHotPlacesApi = async () => {
  return await ApiUtils.request(
    PATH.get_hot_places + `?company_id=${COMPANY_ID}`
  );
};
