import { atom } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

export interface CategoryNewsProps {
  image: string | null;
  image_url: string | null;
  name: string;
  slug: string;
  xid: string;
}
export interface DataProps {
  title: string | number;
  description: any;
  summary: string;
  tags: any;
  slug?: string;
  status: string;
  created_at: string;
  x_news_category_id?: string;
  xid?: string;
  image_url: string | number | boolean;
}
export interface NewsProps {
  data: DataProps[] | [];
  isCalledApi: boolean;
  noMore: boolean;
  offset: number;
  limit: number;
}

export const categoryNewsAtom = atom<CategoryNewsProps[] | []>({
  key: "CATEGORY-NEWS",
  default: [],
  effects_UNSTABLE,
});

export const newsAtom = atom<NewsProps>({
  key: "NEWS",
  default: {
    data: [],
    isCalledApi: false,
    noMore: false,
    limit: 6,
    offset: 0,
  },
  effects_UNSTABLE,
});

export const listNewsAtom = atom<NewsProps[] | []>({
  key: "LIST-NEWS",
  default: [],
  effects_UNSTABLE,
});
