import { ApiUtils } from "./index";
import { PATH } from "./path";

const COMPANY_ID = process.env.REACT_APP_ID_COMPANY;

export const getWarehousesApi = async () => {
  return await ApiUtils.request(
    PATH.get_warehouses + `&filters=company_id%20eq%20"${COMPANY_ID}"`
  );
};
