import React from "react";
import logo from "images/logoOnly.svg";

const LoadingWithLogo = () => {
  return (
    <div className="visible  w-full h-full flex flex-col justify-center items-center space-y-5">
      <img src={logo} alt="" className="animate-spin" />
      <span className="text-2xl font-semibold">Đang tải...</span>
    </div>
  );
};

export default LoadingWithLogo;
