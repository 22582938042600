import { useAsyncEffect, useMount, useRequest } from "ahooks";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  getAllCategoriesApi,
  getServiceCategoriesApi,
} from "services/api/categoryApi";
import {
  ProductCategoryProps,
  ServiceCategoryProps,
  SubProductCategoryProps,
  productCategoryAtom,
  serviceCategoryAtom,
  subCategorySelector,
} from "./category";
import { useCompanyId } from "atom/company/useCompany";

export const useCategory = () => {
  const [productCategory, setProductCategory] =
    useRecoilState<ProductCategoryProps[]>(productCategoryAtom);
  const [serviceCategory, setServiceCategory] =
    useRecoilState<ServiceCategoryProps[]>(serviceCategoryAtom);
  const subCategories =
    useRecoilValue<SubProductCategoryProps[]>(subCategorySelector);
  const { companyId } = useCompanyId();

  const requestGetProductCategories = useRequest(
    (companyId) => getAllCategoriesApi(companyId),
    {
      cacheKey: "get-product-categories",
      manual: true,
      onSuccess: (res: any) => setProductCategory(res.data.categories),
      onError: (err: any) => console.log("Api product category", err.message),
    }
  );

  const requestGetServiceCategories = useRequest(
    (id) => getServiceCategoriesApi(id),
    {
      cacheKey: "get-service-categories",
      manual: true,
      onSuccess: (res: any) => setServiceCategory(res?.data),
      onError: (err: any) => console.log("Api service category", err.message),
    }
  );

  useAsyncEffect(async () => {
    if (productCategory.length < 1) {
      requestGetProductCategories.runAsync(companyId);
    }
    if (serviceCategory.length < 1) {
      requestGetServiceCategories.runAsync(companyId);
    }
  }, [companyId]);

  return {
    productCategory,
    subCategories,
    serviceCategory,
    requestGetProductCategories,
    requestGetServiceCategories,
    setProductCategory,
    setServiceCategory,
  };
};
