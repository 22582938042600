import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { EllipseCenter } from "images/svg";
import carRight from "images/carRight.svg";

import btnChplay from "images/chplay.svg";
import btnZalo from "images/zalo.svg";
import btnApple from "images/appstore.svg";
import CHplayImage from "images/chplay.png";
import ZaloImage from "images/zalo.png";
import AppleImage from "images/appstore.png";

// import LazyImage from "shared/LazyImage/LazyImage";

const SectionDowloadApp = () => {
  return (
    <div className="relative container pb-0 pt-12 md:pt-24 lg:py-20">
      <BackgroundSection className="bg-[#EFF5FA] mx-auto dark:bg-[#111823]">
        {/* <BgGlassmorphism /> */}
        <div className="hidden lg:block absolute right-5 top-0 h-full max-w-xl xl:max-w-2xl rounded-3xl overflow-hidden">
          <EllipseCenter className="w-full md:w-[450px] h-full md:h-[435px] mr-6 " />
        </div>
        <img
          src={carRight}
          alt=""
          className="hidden lg:block z-20 absolute top-0 right-0 visible h-[265px]"
          style={{
            top: 0,
            right: 0,
            transform: "translate(25%, 35%)",
          }}
        />
      </BackgroundSection>

      <div className="relative inline-block px-5 md:px-[58px]">
        <h2 className="text-3xl md:text-[38px] font-semibold text-colorPrimary">
          Trải nghiệm trọn vẹn cùng 3GO
        </h2>
        <span className="block mt-[30px] max-w-md text-textPrimary font-semibold dark:text-neutral-400">
          Lái xe an toàn cùng 3GO với thủ tục đặt xe đơn giản và thanh toán dễ
          dàng giúp bạn an tâm khi lái xe
        </span>
        <div className="flex flex-row gap-2 items-center justify-center xl:justify-start mx-auto md:mx-0 mt-5 md:mt-10">
          <button>
            <img alt="" src={btnChplay} className="dark:hidden" />
            <img alt="" src={CHplayImage} className="hidden dark:block" />
          </button>
          <button>
            <img alt="" src={btnApple} className="dark:hidden" />
            <img alt="" src={AppleImage} className="hidden dark:block" />
          </button>
          <button>
            <img alt="" src={btnZalo} className="dark:hidden" />
            <img alt="" src={ZaloImage} className="hidden dark:block" />
          </button>
        </div>

        <div className="block lg:hidden mt-10 max-w-2xl rounded-3xl overflow-hidden">
          <img src={carRight} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionDowloadApp;
