import React, { useEffect, useState } from "react";
import { getItemFromStore, ITEM_STORE_KEY } from "helpers/utils";
import { Outlet, useNavigate } from "react-router-dom";

const AuthLayout = () => {
  const navigate = useNavigate();
  const token = getItemFromStore(ITEM_STORE_KEY.USER_TOKEN);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (token) return navigate("/");
    // setIsLoading(false);
  }, []);

  return <Outlet />;
};

export default AuthLayout;
