import { sha256 } from "js-sha256";
const CryptoJS = require("crypto-js");

const PRIVATE_KEY = process.env.REACT_APP_PAYMENT_PRIVATE_KEY;

export const hmac = (data) => {
  let dataOverallMac = Object.keys(data)
    .sort()
    .map((key) => `${key}=${data[key]}`)
    .join("&");
  // Tạo overall mac từ dữ liệu
  const reqOveralMac = sha256.hmac(PRIVATE_KEY, dataOverallMac);
  return reqOveralMac;
};

export const generateCodeChallenge = () => {
  const randomBytes = CryptoJS.lib.WordArray.random(32);
  const codeVerifier = CryptoJS.enc.Base64url.stringify(randomBytes);
  const codeChallenge = CryptoJS.enc.Base64url.stringify(
    CryptoJS.SHA256(codeVerifier)
  );

  return {
    verifier: codeVerifier,
    challenge: codeChallenge,
  };
};
