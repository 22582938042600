import * as yup from "yup";
import { USER_REGISTER } from "./fieldName";
import { REGEX } from "./regex";

export const UR = USER_REGISTER;

export const schemaRegister = yup.object({
  [UR.NAME]: yup.string().required("Vui lòng nhập Tên"),
  [UR.PHONE]: yup
    .string()
    .required("Vui lòng nhập số điện thoại")
    .matches(RegExp(REGEX.NUMBER_PHONE2), "Số điện thoại không hợp lệ"),
  [UR.EMAIL]: yup
    .string()
    .required("Vui lòng nhập email.")
    .email("Email không hợpp lệ."),
  [UR.PASSWORD]: yup
    .string()
    .matches(
      RegExp(REGEX.PASSWORD),
      "Mật khẩu phải có ít nhất 8 ký tự, bao gồm ít nhất một chữ cái viết thường, một chữ cái viết hoa, một chữ số và một ký tự đặc biệt."
    )
    .required("Vui lòng nhập mật khẩu."),
});
