import { atom } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

export interface FilterProps {
  keyword?: string | null;
  rangePrice?: {
    lower: number;
    upper: number;
  };
  orderBy?: "asc" | "desc" | null;
  categoryId?: string | null;
  transmission?: string | null;
  seats?: string | number | null;
  fuel?: string | null;
  position?: {
    latitude?: number | null;
    longitude?: number | null;
  };
  address?: {
    province?: {
      title: string;
      id: number;
    } | null;
    district?: {
      title: string;
      id: number;
    } | null;
  };
  date?: {
    startDate?: string | null;
    endDate?: string | null;
  };
  serviceType?: "selfDriving" | "withDriver" | "rental";
  isDeliveryToRenter?: boolean;
  isLongtermRental?: boolean;
  isChauffeurDriven?: boolean;
}

export const initialFilter: FilterProps = {
  keyword: null,
  rangePrice: { lower: 0, upper: 10000000 },
  orderBy: null,
  categoryId: null,
  transmission: null,
  seats: null,
  fuel: null,
  position: {
    latitude: null,
    longitude: null,
  },
  address: {
    province: null,
    district: null,
  },
  date: {
    startDate: null,
    endDate: null,
  },
  isDeliveryToRenter: false,
  isLongtermRental: false,
  isChauffeurDriven: false,
  serviceType: "selfDriving",
};

export const filterAtom = atom<FilterProps>({
  key: "FILTER",
  default: initialFilter,
  effects_UNSTABLE,
});
