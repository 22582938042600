import React from "react";

interface TextWithLeftBorderProps {
  title: string;
  className?: string;
}

const TextWithLeftBorder: React.FC<TextWithLeftBorderProps> = ({
  title,
  className,
}) => {
  return (
    <div className={`flex flex-row space-x-1 ${className}`}>
      <div className="w-1 bg-[#8CC542] rounded-full my-1" />
      <span className={`text-textPrimary dark:text-neutral-200`}>{title}</span>
    </div>
  );
};

export default TextWithLeftBorder;
