import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { Page } from "./types";
import Page404 from "containers/Page404/Page404";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import BlogPage from "containers/BlogPage/BlogPage";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import AppLayout from "layouts/AppLayout";
import UserLayout from "layouts/UserLayout";

import SearchPage from "containers/SearchPage/SearchPage";
import LayoutInformation from "containers/AuthorPage/LayoutInformation";
import ListCarPage from "containers/ListCar";
import ForgotPassPage from "containers/ForgotPassPage";
import AuthLayout from "layouts/AuthLayout";
import BecomADriverPage from "containers/BecomADriverPage";
import CurrentRentalDetailPage from "containers/AuthorPage/CurrentRentalDetailPage";
import PageZaloLogin from "containers/PageLogin/PageZaloLogin";
import LoadingWithLogo from "components/LoadingWithLogo";
import PageFacebookLogin from "containers/PageLogin/PageFacebookLogin";
import PageGoogleLogin from "containers/PageLogin/PageGoogleLogin";

const CheckOutPage = lazy(() => import("containers/CheckOutPage/CheckOutPage"));
const FormRegisterPage = lazy(
  () => import("containers/BecomADriverPage/FormRegisterPage")
);
const ListingCarDetailPage = lazy(
  () =>
    import(
      "containers/ListingDetailPage/listing-car-detail/ListingCarDetailPage"
    )
);
const BlogSingle = lazy(() => import("containers/BlogPage/BlogSingle"));
const ChinhSachVaQuyDinh = lazy(
  () => import("containers/FooterPage/ChinhSachVaQuyDinh")
);
const DieuKhoanVaDichVu = lazy(
  () => import("containers/FooterPage/DieuKhoanVaDichVu")
);
const ComplaintDisputeResolution = lazy(
  () => import("containers/FooterPage/ComplaintDisputeResolution")
);
const AboutUs = lazy(() => import("containers/FooterPage/AboutUs"));
const OperationalRegulations = lazy(
  () => import("containers/FooterPage/OperationalRegulations")
);
const InsurancePolicy = lazy(
  () => import("containers/FooterPage/InsurancePolicy")
);
const InformationSecurity = lazy(
  () => import("containers/FooterPage/InformationSecurity")
);
const CarBookingGuide = lazy(
  () => import("containers/FooterPage/CarBookingGuide")
);
const GuideForCarOwners = lazy(
  () => import("containers/FooterPage/GuideForCarOwners")
);
const PaymentGuide = lazy(() => import("containers/FooterPage/PaymentGuide"));
const FAQ = lazy(() => import("containers/FooterPage/FAQ"));
const CustomerReviews = lazy(
  () => import("containers/FooterPage/CustomerReviews")
);

// auth
const UtilityPage = lazy(() => import("containers/AuthorPage/UtilityPage"));
const CurrentRentalPage = lazy(
  () => import("containers/AuthorPage/CurrentRentalPage")
);
const RentalManagement = lazy(
  () => import("containers/AuthorPage/RentalManagement")
);
const FavoriteVehiclesPage = lazy(
  () => import("containers/AuthorPage/FavoriteVehiclesPage")
);
const AddressBookPage = lazy(
  () => import("containers/AuthorPage/AddressBookPage")
);
const TermsAndPoliciesPage = lazy(
  () => import("containers/AuthorPage/TermsAndPoliciesPage")
);
const ContactAndFeedbackPage = lazy(
  () => import("containers/AuthorPage/ContactAndFeedbackPage")
);
const AccountPass = lazy(() => import("containers/AccountPage/AccountPass"));
const AccountBilling = lazy(
  () => import("containers/AccountPage/AccountBilling")
);

export const pages: Page[] = [
  {
    path: "/",
    component: AppLayout,
    children: [
      { path: "/", exact: true, component: ListingCarPage },
      { path: "/become-a-driver", exact: true, component: BecomADriverPage },
      { path: "/checkout", component: CheckOutPage },
      {
        path: "/become-a-driver/form",
        exact: true,
        component: FormRegisterPage,
      },

      { path: "/listing-car", component: ListCarPage },
      {
        path: "/car-detail/:slug",
        exact: true,
        component: ListingCarDetailPage,
      },
      { path: "/news", component: BlogPage },
      { path: "/news/:slug", exact: true, component: BlogSingle },
      { path: "/search", component: SearchPage },
      { path: "/policies-and-regulations", component: ChinhSachVaQuyDinh },
      { path: "/terms-of-service", component: DieuKhoanVaDichVu },
      {
        path: "/complaint-dispute-resolution",
        component: ComplaintDisputeResolution,
      },
      { path: "/about-us", component: AboutUs },
      { path: "/operational-regulations", component: OperationalRegulations },
      { path: "/insurance-policy", component: InsurancePolicy },
      { path: "/information-security", component: InformationSecurity },
      { path: "/car-booking-guide", component: CarBookingGuide },
      { path: "/guide-for-car-owners", component: GuideForCarOwners },
      { path: "/payment-guide", component: PaymentGuide },
      { path: "/faq", component: FAQ },
      { path: "/customer-reviews", component: CustomerReviews },
      {
        path: "/view-mode/chinh-sach-va-quy-dinh",
        component: ChinhSachVaQuyDinh,
      },
      { path: "/view-mode/dieu-khoan-dich-vu", component: DieuKhoanVaDichVu },
      {
        path: "/current-rental/:slug",
        component: CurrentRentalDetailPage,
      },
    ],
  },
  {
    path: "/",
    component: UserLayout,
    children: [
      {
        path: "/",
        component: AuthorPage,
        children: [
          { path: "author/utility", component: UtilityPage },
          { path: "author/current-rental", component: CurrentRentalPage },
          { path: "author/rental-management", component: RentalManagement },
          { path: "author/favorite-vehicles", component: FavoriteVehiclesPage },
          { path: "author/address-book", component: AddressBookPage },
          {
            path: "author/terms-and-policies",
            component: TermsAndPoliciesPage,
          },
          {
            path: "author/contact-and-feedback",
            component: ContactAndFeedbackPage,
          },
          {
            path: "/",
            component: LayoutInformation,
            children: [
              { path: "author/information", component: AccountPage },
              {
                path: "author/information/password",
                exact: true,
                component: AccountPass,
              },
              {
                path: "author/information/billing",
                exact: true,
                component: AccountBilling,
              },
            ],
          },
        ],
      },
      // { path: "rental-car", component: RentalCarPage },
    ],
  },
  {
    path: "/",
    component: AuthLayout,
    children: [
      { path: "/login", exact: true, component: PageLogin },
      { path: "/login/zalo", exact: true, component: PageZaloLogin },
      { path: "/login/facebook", exact: true, component: PageFacebookLogin },
      { path: "/login/google", exact: true, component: PageGoogleLogin },
      { path: "/signup", exact: true, component: PageSignUp },
      { path: "/forgot-pass", exact: true, component: ForgotPassPage },
    ],
  },
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  const renderChildren = (children) => {
    if (!children) return null;
    return children.map((child, idx) => (
      <Route key={idx} path={child.path} element={<child.component />}>
        {renderChildren(child.children)}
      </Route>
    ));
  };

  return (
    <Suspense
      fallback={
        <div className="w-screen h-screen flex justify-center items-center">
          <LoadingWithLogo />
        </div>
      }
    >
      <BrowserRouter>
        <ScrollToTop />
        <SiteHeader />
        <Routes>
          {pages.map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />}>
              {renderChildren(route.children)}
            </Route>
          ))}
          <Route
            path="/author"
            element={<Navigate to="/author/information" />}
          />
          <Route path="*" element={<Page404 />} />
        </Routes>

        {WIN_WIDTH < 768 && <FooterNav />}
        <Footer />
      </BrowserRouter>
    </Suspense>
  );
};

export default MyRoutes;
