import { atom } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

export interface PlaceProps {
  city_id: number;
  country_id: number;
  id: number;
  image: string;
  image_url: string;
  province_id: number;
  title: string;
  type: string;
  xid: string;
}

export const placeAtom = atom<PlaceProps[]>({
  key: `HOT-PLACE`,
  default: [],
  effects_UNSTABLE,
});
