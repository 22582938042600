import axios from "axios";
import { useRequest } from "ahooks";
// import { uploadFileSapo } from "services/api/uploadFileApi";
import { getDataFromStorage } from "services/storage";
import { ITEM_STORE_KEY } from "helpers/utils";
import { uploadFileBase64Api } from "services/api/ratingApi";
import { useUser } from "atom/user/useUser";

export const BASE_URL_API = "https://api.aimini.vn/api";

export const useUploadFile = () => {
  const headers = {
    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzMsImlhdCI6MTcxMTkzNzM2OSwiZXhwIjoxNzE0NTI5MzY5fQ.iJJIoXUHKU9Qplxx56wiF3BvTjfJhluJMhXvjyvMobE`,
    "Content-Type": "multipart/form-data",
  };

  const { token } = useUser();

  const uploadFile = async (files) => {
    const formData = new FormData();
    if (files) {
      Array.from(files).map((file: any) => formData.append("files", file));
      try {
        const response = await axios.post(`${BASE_URL_API}/upload`, formData, {
          headers,
        });
        if (response) return response.data; // Response data from the server
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const removeFile = async (files) => {
    // if (files) {
    //   try {
    //     files.map(async(idFile) => {
    //         const response = await axios.delete(`${BASE_URL_AIMINI}/upload/files/${idFile}`, { headers } );
    //         console.log(response.data);
    //     })
    //   } catch (error) {
    //     console.error("Error uploading file:", error);
    //   }
    // }
  };

  // const requestUploadFile = useRequest(
  //   (file, token = getDataFromStorage(ITEM_STORE_KEY.USER_TOKEN)) =>
  //     uploadFileSapo(file, token),
  //   {
  //     cacheKey: "upload-file-sapo",
  //     manual: true,
  //   }
  // );

  const uploadFilePos = async (file, folder = "prizes") => {
    console.log({ file });
    let formData = new FormData();
    formData.append("image", file[0]);
    formData.append("folder", folder);
    const data = await requestUploadFile.runAsync(formData);
    return data;
  };

  const requestUploadFile = useRequest(
    (form) => uploadFileBase64Api(form, token),
    {
      cacheKey: "upload-file",
      manual: true,
      onError: (err: any) => {
        console.error("API UPLOADFILE", err.error);
      },
    }
  );

  return { uploadFilePos, uploadFile, removeFile, requestUploadFile };
};
