import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";

import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { useUser } from "atom/user/useUser";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const {
    user: { user },
  } = useUser();

  const navigate = useNavigate();

  return (
    <div className={`nc-MainNav1 relative z-[99999] ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center z-[999]">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10 z-[9999]">
          <Logo />
          <Navigation />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3 z-50">
          <HeroSearchForm2MobileFactory />
        </div>
        <SwitchDarkMode className="mx-3" />
        <button
          className="hidden md:flex text-2xl md:text-[28px] w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none  items-center justify-center mr-3"
          onClick={() => navigate("/search")}
        >
          <i className="las la-search"></i>
        </button>

        {!user ? (
          <div className="hidden px-2 md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
            <div className="hidden xl:flex items-center space-x-4">
              <Link
                to="/signup"
                className="bg-textSecondary-50 text-textPr hover:bg-textPrimary-300 font-semibold "
              >
                Đăng ký
              </Link>
              <ButtonPrimary href="/login" className="px-10 w-[200px]">
                Đăng nhập
              </ButtonPrimary>
            </div>
            <div className="flex xl:hidden items-center">
              <SwitchDarkMode className="md:hidden" />
              <div className="px-0.5" />
              <MenuBar />
            </div>
          </div>
        ) : (
          <div className="hidden md:flex items-center space-x-[36px] ">
            <NotifyDropdown />
            <AvatarDropdown />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainNav1;
