import React, { ReactNode } from "react";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import { useSetting } from "atom/setting/useSetting";
import MobileFooterSticky from "./(components)/MobileFooterSticky";

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const { setting, setShowImageGalleryModal } = useSetting();

  const handleCloseModalImageGallery = () => {
    setShowImageGalleryModal({
      show: false,
      images: [],
    });
  };

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={setting.imageGalleryModal.show}
        onClose={handleCloseModalImageGallery}
        images={setting.imageGalleryModal.images}
      />
      <div className="container ListingDetailPage__content">{children}</div>
    </div>
  );
};

export default DetailPagetLayout;
