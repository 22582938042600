import { useRequest, useMount } from "ahooks";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  OAProps,
  OASelector,
  WarehouseProps,
  warehouseAtom,
  warehouseIdSelector,
} from "./warehouse";
import { getWarehousesApi } from "services/api/warehouseApi";
import { useEffect } from "react";

export const useWarehouse = () => {
  const [warehouse, setWarehouse] =
    useRecoilState<WarehouseProps[]>(warehouseAtom);
  const warehouseId = useRecoilValue<string | null>(warehouseIdSelector);
  const OA = useRecoilValue<OAProps | unknown>(OASelector);

  const requestGetWarehouse = useRequest(() => getWarehousesApi(), {
    cacheKey: "get-warehouses",
    manual: true,
    onSuccess: (res: any) => setWarehouse(res?.data),
    onError: (err: any) => console.log("Api warehouse:", err.message),
  });

  // useMount(() => {
  //   if (warehouse.length < 1) {
  //     requestGetWarehouse.runAsync();
  //   }
  // });

  useEffect(() => {
    if (warehouse.length === 0) {
      requestGetWarehouse.runAsync();
    }
  }, [warehouse]);

  return {
    warehouse,
    warehouseId,
    OA,
    requestGetWarehouse,
    setWarehouse,
  };
};
