import React, { FC } from "react";
import rightImgPng from "images/Audi1.png";
import Title from "components/Title";
import { EllipseLeft } from "images/svg";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeaturesWithList: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className="nc-SectionOurFeatures relative pt-0 md:pt-14 lg:py-0"
      data-nc-id="SectionOurFeatures"
    >
      <BgGlassmorphism />
      {/* ellipse */}
      <EllipseLeft className="hidden lg:flex absolute left-0 top-0 w-full max-w-[524px] z-20" />

      <div
        className={` flex flex-col justify-start space-x-5 md:space-x-20 ${
          type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
      >
        <div className="flex-grow max-w-[955px] flex-1 z-20 lg:-ml-36 mt-0">
          <img alt="" src={rightImg} className="w-full" />
        </div>
        <div
          className={`lg:max-w-[480px] flex-shrink-0 lg:mt-0 gap-8 flex flex-col`}
        >
          <Title
            className={"text-2xl lg:text-[38px] font-semibold leading-[45.6px]"}
          >
            Thủ tục thuê xe đơn giản
          </Title>

          <ul className="space-y-5 md:space-y-10">
            <li className="flex flex-row space-x-5 md:space-x-5">
              <span className="text-3xl md:text-[40px] font-semibold text-[#DCE2E8] leading-[68px]">
                01
              </span>
              <div>
                <span className="block text-xl md:text-2xl font-normal text-textPrimary dark:text-neutral-200">
                  Chọn xe và thời gian thuê
                </span>
                <span className="block mt-3 text-[#868A8D] md:text-lg font-normal">
                  Chọn thời gian, địa điểm và đặt xe qua ứng dụng 3GO
                </span>
              </div>
            </li>
            <li className="flex flex-row space-x-5 md:space-x-5">
              <span className="text-3xl md:text-[40px] font-semibold text-[#DCE2E8] leading-[68px]">
                02
              </span>
              <div>
                <span className="block text-xl md:text-2xl font-normal text-textPrimary dark:text-neutral-200">
                  Thủ tục xác thực
                </span>
                <span className="block mt-3 text-[#868A8D] md:text-lg font-normal">
                  Xác thực CCCD/hộ chiếu, bằng lái xe. Đặt cọc và thanh toán
                  tiện lợi
                </span>
              </div>
            </li>
            <li className="flex flex-row space-x-5 md:space-x-5">
              <span className="text-3xl md:text-[40px] font-semibold text-[#DCE2E8] leading-[68px]">
                03
              </span>
              <div>
                <span className="block text-xl md:text-2xl font-normal text-textPrimary dark:text-neutral-200">
                  Bắt đầu hành trình
                </span>
                <span className="block mt-3 text-[#868A8D] md:text-lg font-normal">
                  Chủ động nhận xe và kích hoạt mở xe dễ dàng bằng ứng dụng 3GO
                </span>
              </div>
            </li>
            <li className="flex flex-row space-x-5 md:space-x-5">
              <span className="text-3xl md:text-[40px] font-semibold text-[#DCE2E8] leading-[68px]">
                04
              </span>
              <div>
                <span className="block text-xl md:text-2xl font-normal text-textPrimary dark:text-neutral-200">
                  Trả xe và kết thúc chuyến đi
                </span>
                <span className="block mt-3 text-[#868A8D] md:text-lg font-normal">
                  Tận hưởng chuyến đi an toàn và vui vẻ
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionOurFeaturesWithList;
