import moment from "moment";
import { useMount, useRequest } from "ahooks";
import { useRecoilState } from "recoil";
import { Payment } from "zmp-sdk";
import { EventName, events } from "zmp-sdk/apis";
import { bookingAtom, bookingCheckoutAtom } from "./booking";
import { useUser } from "atom/user/useUser";
import {
  checkoutServiceApi,
  getMyBookingsApi,
  zaloCallBackApi,
} from "services/api/bookingApi";
import { showCustomToast } from "shared/toast";
import { hmac } from "utils";
// import { globalLoading, showCustomToast } from "components/common";

export type BookingStatusType =
  | "all"
  | "renting"
  | "cancelled"
  | "completed"
  | "review";

export type BookingCheckoutType =
  | "hasDriver"
  | "date"
  | "address"
  | "referralCode"
  | "note"
  | "point"
  | "promotion"
  | "car";

export const useBooking = () => {
  const { token }: any = useUser();
  const [booking, setBooking] = useRecoilState(bookingAtom);
  const [bookingCheckout, setBookingCheckout] =
    useRecoilState(bookingCheckoutAtom);

  const requestBookingService = useRequest(
    (data) => checkoutServiceApi(data, token),
    {
      cacheKey: "booking-service",
      manual: true,
      onError: (err) => console.log("API BOOKING SERVICE", err),
    }
  );

  const requestMyBookings = useRequest(() => getMyBookingsApi(token), {
    cacheKey: "my-booking",
    manual: true,
    onSuccess: (res: any) => setBooking(res?.data?.bookings),
    onError: (err) => console.log("API MY BOOKINGS", err),
  });

  const requestZaloCallBack = useRequest((data) => zaloCallBackApi(data), {
    cacheKey: "zalo-callback",
    manual: true,
    onError: (err: any) => console.log("API CALLBACK: ", err),
  });

  const filterBookings = (type: BookingStatusType) => {
    if (booking.length < 1) return booking;
    let data;
    switch (type) {
      case "all":
        data = booking;
        break;
      case "renting":
        data = booking.filter((item) => item.booking_status === "confirmed");
        break;
      case "cancelled":
        data = booking.filter((item) => item.booking_status === "canceled");
        break;
      case "completed":
        data = booking.filter((item) => item.booking_status === "completed");
        break;
      case "review":
        data = booking.filter(
          (item) =>
            item.booking_status === "completed" && item.rating_id == null
        );
        break;
      default:
        data = [];
        break;
    }
    return data;
  };

  const updateBookingCheckout = (type: BookingCheckoutType, value) => {
    let newData = bookingCheckout;
    switch (type) {
      case "hasDriver":
        newData = { ...newData, hasDriver: value };
        break;
      case "date":
        newData = { ...newData, date: value };
        break;
      case "address":
        newData = { ...newData, address: value };
        break;
      case "referralCode":
        newData = { ...newData, referralCode: value };
        break;
      case "note":
        newData = { ...newData, note: value };
        break;
      case "point":
        newData = { ...newData, point: value };
        break;
      case "promotion":
        newData = { ...newData, promotion: value };
        break;
      case "car":
        newData = { ...newData, car: value };
        break;
      default:
        newData = newData;
        break;
    }
    setBookingCheckout(newData);
  };

  const resetBookingCheckout = () => {
    setBookingCheckout({
      car: null,
      date: {
        day: 1,
        pickupDate: moment().toDate(),
        returnDate: moment().add(1, "days").toDate(),
      },
      hasDriver: false,
      promotion: null,
      point: 0,
      referralCode: "",
      note: "",
      address: {
        detail: "",
        type: "hub",
      },
    });
  };

  const bookingService = ({
    data,
    success,
    error,
  }: {
    data: any;
    success?: (res?: any) => void;
    error?: (err?: any) => void;
  }) => {
    requestBookingService
      .runAsync(data)
      .then((res: any) => !!success && success(res))
      .catch((err) => !!error && error(err));
  };

  const checkoutZalo = async ({
    data,
    items,
    amount,
    success,
    error,
  }: {
    data: any;
    items: { id: string; amount: number }[];
    amount: number;
    success?: (res?: any) => any;
    error?: (err?: any) => any;
  }) => {
    // globalLoading.show();

    try {
      const { orderId } = await Payment.createOrder({
        desc: "Thanh toán dịch vụ",
        item: items,
        amount: amount,
      });
      // globalLoading.hide();

      let eventBanking = EventName.OnDataCallback;
      let eventOther = EventName.OpenApp;

      const onBookingSuccess = (res) => {
        // globalLoading.hide();
        resetBookingCheckout();
        showCustomToast({
          type: 1,
          message: "Đặt dịch vụ thành công!",
        });
        !!success && success(res);
      };

      const onBookingError = (err) => {
        // globalLoading.hide();
        showCustomToast({
          type: 2,
          message: err?.error?.message,
        });
        !!error && error(err);
      };

      //*Phương thức CHUYỂN KHOẢN NGÂN HÀNG
      events.once(eventBanking, (resp) => {
        events.off(eventOther); //remove event khác
        if (resp?.eventType === "PAY_BY_BANK" && resp?.data.appTransID) {
          // globalLoading.show();
          requestBookingService
            .runAsync({
              ...data,
              zalo_order_id: orderId,
            })
            .then(onBookingSuccess)
            .catch(onBookingError);
        }
      });

      //*Các phương thức khác: COD, VNPAY, MOMO, ...
      events.once(eventOther, (resEvent) => {
        events.off(eventBanking); //remove event khác
        const params = resEvent?.path;
        if (params.includes("/check-transaction")) {
          // globalLoading.show();
          Payment.checkTransaction({
            data: params,
            success: (resTrans) => {
              if (
                resTrans?.method.startsWith("COD") ||
                (resTrans?.method.startsWith("VNPAY") &&
                  resTrans?.resultCode === 1)
              ) {
                requestBookingService
                  .runAsync({
                    ...data,
                    zalo_order_id: resTrans?.orderId,
                  })
                  .then((resCheckout) => {
                    requestZaloCallBack
                      .runAsync({
                        data: resTrans,
                        mac: hmac(resTrans),
                      })
                      .then(onBookingSuccess)
                      .catch(onBookingError);
                    console.log("DATA CHECKOUT: ", {
                      ...data,
                      zalo_order_id: resTrans?.orderId,
                    });
                  })
                  .catch(onBookingError);
              } else {
                onBookingError({
                  error: {
                    message: "Thanh toán thất bại!",
                  },
                });
              }
            },
            fail: (err) => {
              onBookingError(err);
            },
          });
        }
      });
    } catch (err) {
      // globalLoading.hide();
      showCustomToast({
        type: 2,
        message: "Thanh toán thất bại, vui lòng thử lại sau!",
      });
      console.log(err);
      !!error && error(err);
    }
  };

  useMount(() => {
    if (token || !booking?.length) {
      requestMyBookings.runAsync();
    }
  });

  return {
    booking,
    bookingCheckout,
    requestBookingService,
    requestMyBookings,
    setBooking,
    setBookingCheckout,
    bookingService,
    filterBookings,
    updateBookingCheckout,
    resetBookingCheckout,
    checkoutZalo,
  };
};
