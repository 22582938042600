import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import image from "images/becomAdrive.svg";
import { CarHorizontalIcon, EllipseRight, UserIcon } from "images/svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useUser } from "atom/user/useUser";
import { useRental } from "atom/rental/useRental";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import LazyImage from "shared/LazyImage/LazyImage";

const selfData = ["Thông tin xe", "Hình ảnh", "Giấy tờ xe", "Giá cho thuê"];
const longTermRentalData = [
  "Thông tin xe",
  "Hình ảnh",
  "Giấy tờ xe",
  "Tư vấn chủ xe & báo chí",
];

const renderButton = ({
  id,
  icon,
  title,
  active,
  onClick,
}: {
  id: string;
  icon: ReactNode;
  title: string;
  active?: boolean;
  onClick?: () => void;
}) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={classNames(
        "flex flex-row justify-center space-x-2 w-full border rounded-full min-w-[150px] md:min-w-[205px] items-center py-3 border-[#DCE2E8]",
        active && "border-colorPrimary text-colorPrimary border-4"
      )}
    >
      {icon}
      <span className="w-fit">{title}</span>
    </button>
  );
};

const BecomADriverPage = () => {
  const { user, token } = useUser();
  const { setRentalStep, setRegister, register } = useRental();
  const navigate = useNavigate();

  const handleRegister = () => {
    if (!user || !token) return navigate("/login");

    setRentalStep(1);
    navigate("/become-a-driver/form");
  };

  return (
    <div className="container py-8 md:py-16 ">
      <Helmet>
        <title>3GO | Trở Thành Chủ Xe</title>
        <meta
          name="description"
          content="Đăng ký trở thành chủ xe trên 3GO để cho thuê xe và tận hưởng các lợi ích từ nền tảng của chúng tôi. Dễ dàng quản lý xe và giao dịch."
        />
        <meta
          name="keywords"
          content="trở thành chủ xe, cho thuê xe, đăng ký xe, 3GO"
        />
        <meta property="og:title" content="3GO | Trở Thành Chủ Xe" />
        <meta
          property="og:description"
          content="Đăng ký trở thành chủ xe trên 3GO để cho thuê xe và tận hưởng các lợi ích từ nền tảng của chúng tôi. Dễ dàng quản lý xe và giao dịch."
        />
        <meta
          property="og:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <meta
          property="og:url"
          content="https://3go.ui.aimini.vn/become-owner"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="3GO | Trở Thành Chủ Xe" />
        <meta
          name="twitter:description"
          content="Đăng ký trở thành chủ xe trên 3GO để cho thuê xe và tận hưởng các lợi ích từ nền tảng của chúng tôi. Dễ dàng quản lý xe và giao dịch."
        />
        <meta
          name="twitter:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              name: "Trở Thành Chủ Xe",
              url: "https://3go.ui.aimini.vn/become-owner",
              description:
                "Đăng ký trở thành chủ xe trên 3GO để cho thuê xe và tận hưởng các lợi ích từ nền tảng của chúng tôi. Dễ dàng quản lý xe và giao dịch.",
              image:
                "https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png",
            }),
          }}
        />
      </Helmet>

      <EllipseRight className="hidden md:flex absolute right-0 top-0" />

      <BgGlassmorphism />
      <div className="flex z-20 justify-center flex-col items-center space-y-9 relative">
        {/* <BackgroundSection /> */}
        {/* <NcImage src={image} alt="img" className="max-w-[340px]" /> */}
        <LazyImage
          alt=""
          src={image}
          width={340}
          height={120}
          className="max-w-[340px]"
        />
        <div className="flex">
          <div className="flex flex-row space-x-6">
            {renderButton({
              id: "selfDriver",
              icon: <CarHorizontalIcon className="w-4 h-4" />,
              title: "Tự lái",
              active: register.registerType === "selfDriver",
              onClick: () =>
                setRegister((prev) => ({
                  ...prev,
                  registerType: "selfDriver",
                })),
            })}
            {renderButton({
              id: "long_term_rental",
              icon: <UserIcon className="w-4 h-4" />,
              title: "Thuê dài hạn",
              active: register.registerType === "long_term_rental",
              onClick: () =>
                setRegister((prev) => ({
                  ...prev,
                  registerType: "long_term_rental",
                })),
            })}
          </div>
        </div>
        <div className="rounded-md border border-[#E5E7EB] px-6 md:px-12 py-9 flex justify-center flex-col bg-[#FBFBFB] dark:bg-colorSecondary">
          <p className="text-2xl md:w-[430px]">
            {register.registerType === "selfDriver"
              ? "Gia tăng thu nhập hàng tháng cùng 3GO"
              : "Thu nhập tốt & ổn định khi trở thành đối tác cho thuê dài hạn cùng 3GO"}
          </p>
          <span className="text-[#868A8D] mx-auto font-normal">
            Thủ tục đăng ký đơn giản & nhanh chóng:
          </span>

          <ul key={register.registerType} className="space-y-1 mt-4">
            {(register.registerType === "selfDriver"
              ? selfData
              : longTermRentalData
            ).map((item, index) => {
              return (
                <li key={index} className="flex flex-col gap-1">
                  <div className="flex flex-row items-center gap-6">
                    <div className="border border-[#E8E8E8] p-1 rounded-full">
                      <div className="w-8 h-8 text-white bg-[#8CC542] rounded-full flex justify-center items-center">
                        {index + 1}
                      </div>
                    </div>
                    <span className="text-lg md:text-xl">{item}</span>
                  </div>
                  <div
                    className={classNames(
                      "w-[2px] ml-5 h-5 bg-[#E8E8E8]",
                      index ===
                        (register.registerType === "selfDriver"
                          ? selfData
                          : longTermRentalData
                        ).length -
                          1
                        ? "hidden"
                        : "block"
                    )}
                  ></div>
                </li>
              );
            })}
          </ul>
        </div>

        <ButtonPrimary onClick={handleRegister}>
          Đăng ký cho thuê xe
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default BecomADriverPage;
