import React from "react";
import { LookIcon } from "images/svg";

const NotFoundCar = () => {
  return (
    <div className="flex justify-center items-center flex-col text-center mx-auto w-full col-span-4">
      <LookIcon className="w-[10%] fill-colorPrimary" />

      <span className="text-[#8F90A6] font-normal text-xl mt-3">
        Không tìm thấy xe
      </span>
    </div>
  );
};

export default NotFoundCar;
