import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonSecondaryProps extends ButtonProps {}

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
  className = " ",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonSecondary font-medium border bg-white hover:bg-textSecondary focus:ring-0 ring-0 focus:ring-offset-0  dark:bg-colorPrimary  dark:text-neutral-100 ${className}`}
      {...args}
    />
  );
};

export default ButtonSecondary;
