import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

interface IModalReport {
  isOpen: {
    report: boolean;
    cancel: boolean;
    active: boolean;
    PolicyCancel: {
      type: 1 | 2;
      isShow: boolean;
    };
  };
  setIsOpen: React.Dispatch<
    React.SetStateAction<{
      report: boolean;
      cancel: boolean;
      active: boolean;
      PolicyCancel: {
        type: 1 | 2;
        isShow: boolean;
      };
    }>
  >;
}

const renderRadio = (
  name: string,
  id: string,
  label: string,
  defaultChecked?: boolean
) => {
  return (
    <div className="flex items-center">
      <input
        defaultChecked={defaultChecked}
        id={id + name}
        name={name}
        type="radio"
        className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
      />
      <label
        htmlFor={id + name}
        className="ml-3 block font-normal text-textPrimary dark:text-neutral-300"
      >
        {label}
      </label>
    </div>
  );
};

export default function ModalReport({ isOpen, setIsOpen }: IModalReport) {
  return (
    <>
      <Transition appear show={isOpen.report} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() =>
            setIsOpen((prev) => ({
              ...prev,
              report: false,
            }))
          }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-colorPrimary">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-textPrimary text-center dark:text-neutral-200"
                  >
                    Báo cáo xe
                  </Dialog.Title>

                  <div className="mt-5">
                    <span className="text-colorPrimary font-bold dark:text-neutral-200">
                      Lý do báo xấu
                    </span>
                    <form className="flex flex-row justify-between mt-3">
                      {renderRadio("report", "report", "Lý do khác", true)}
                      {renderRadio(
                        "report",
                        "report",
                        "Thông tin xe không chính xác"
                      )}
                    </form>
                  </div>

                  <div className="mt-8">
                    <span className="text-colorPrimary font-bold dark:text-neutral-200">
                      Nội dung
                    </span>
                    <textarea
                      className="bg-neutral-100 border-0 border-t-2 border-primary-500 rounded-md w-full h-48 mt-2 text-textPrimary dark:text-neutral-300"
                      placeholder="Nhập nội dung báo cáo..."
                    />
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="flex mx-auto justify-center rounded-full border border-transparent  px-4 py-2 text-sm font-medium bg-primary-400 hover:bg-primary-600 focus:outline-none focus-visible:ring-2  focus-visible:ring-offset-2 text-neutral-200 dark:text-textPrimary"
                      onClick={() =>
                        setIsOpen((prev) => ({
                          ...prev,
                          report: false,
                        }))
                      }
                    >
                      Gửi báo cáo xe
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
