import React, { FC, memo } from "react";
import { PlaceProps } from "atom/place/place";

export interface CardCategory4Props {
  className?: string;
  taxonomy: PlaceProps;
  isShowContent?: boolean;
  onClick?: () => void;
}

const CardCategory4: FC<CardCategory4Props> = ({
  className = "",
  taxonomy,
  isShowContent = false,
  onClick,
}) => {
  return (
    <div
      className={`nc-CardCategory4 flex flex-col ${className}`}
      data-nc-id="CardCategory4"
      onClick={onClick}
    >
      <div
        // className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-6 h-0 rounded-xl overflow-hidden group`}
        className="h-5/6"
      >
        <img
          alt=""
          src={taxonomy?.image_url}
          className="object-cover w-full h-full rounded-xl"
        />
      </div>
      {isShowContent && (
        <div className="mt-4 px-2 truncate flex justify-start flex-col">
          <h2
            className={`sm:text-lg text-text-900 dark:text-text-100 truncate font-normal lg:text-2xl`}
          >
            {taxonomy?.title}
          </h2>
          {/* <span
            className={`block mt-2 text-lg text-gray-600 dark:text-gray-400 font-bold`}
          >
            {desc}
          </span> */}
        </div>
      )}
    </div>
  );
};

export default memo(CardCategory4);
