import React from "react";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface CommonLayoutProps {
  children?: React.ReactNode;
}

const data = [
  {
    label: "Thông tin tài khoản",
    to: "/author/information",
  },
  {
    label: "Đổi mật khẩu",
    to: "/author/information/password",
  },
  // {
  //   label: "Thanh toán",
  //   to: "/author/information/billing",
  // },
];

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900 mx-0">
      <div className="border-b border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800 sticky top-0 ">
        <div className="flex justify-center md:space-x-14 gap-1">
          {data.map((item, index) => (
            <button
              key={index}
              onClick={() => navigate(item.to)}
              className={`py-2 md:py-8 flex-shrink-0 hover:border-primary-500 hover:text-primary-500 transition-border duration-200 border-b-2 ${
                pathname === item.to
                  ? "border-primary-500 mx-5"
                  : "border-transparent mx-1.5"
              }`}
            >
              <span className="text-sm sm:text-base text-neutral-600 dark:text-neutral-300">
                {item.label}
              </span>
            </button>
          ))}
        </div>
      </div>
      <div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">{children}</div>
    </div>
  );
};

export default CommonLayout;
