import moment from "moment";
import { useMemo } from "react";

import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { formatCurrency } from "utils/formatCurrency";

import walletIcon from "images/icons/ion_wallet-outline.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useUser } from "atom/user/useUser";
import { getPrice } from "helpers/utils";

const renderButton = ({
  user,
  type,
  setModal,
  handleCheckout,
  handleCancel,
  handleSupport,
}) => {
  if (type === "cancel")
    return (
      <div className="flex flex-col space-y-3">
        <ButtonPrimary onClick={handleSupport}>Liên hệ hỗ trợ</ButtonPrimary>
        <button
          className="text-textSecondary font-semibold"
          onClick={handleCancel}
        >
          Hủy chuyến
        </button>
      </div>
    );
  return user && user?.ekyc_status !== "verified" ? (
    <ButtonPrimary
      className="bg-red-500 dark:bg-red-300"
      onClick={() =>
        setModal((prev) => ({
          ...prev,
          active: true,
        }))
      }
    >
      Xác thực tài khoản!
    </ButtonPrimary>
  ) : (
    <ButtonPrimary onClick={handleCheckout && handleCheckout}>
      Đặt xe
    </ButtonPrimary>
  );
};

const FormPay = ({
  handleCheckout,
  currentData,
  type,
  setModal,
}: {
  handleCheckout?: () => void;
  currentData?: any;
  type?: string;

  setModal: React.Dispatch<
    React.SetStateAction<{
      report: boolean;
      cancel: boolean;
      active: boolean;
      PolicyCancel: {
        type: 1 | 2;
        isShow: boolean;
      };
    }>
  >;
}) => {
  const {
    user: { user },
  } = useUser();
  const { currentPrice } = getPrice(currentData);

  const countDate = useMemo(() => {
    if (!currentData?.startDate || !currentData?.endDate) return 0;
    const startDate = moment(currentData.startDate);
    const endDate = moment(currentData.endDate);
    const startTime = moment(currentData.startTime);
    const endTime = moment(currentData.endTime);

    const dateDifference = endDate.diff(startDate, "days");

    // just return days
    return dateDifference;
    // console.log(dateDifference);
    const timeDifference = endTime.diff(startTime);
    const timeDifferenceInDays = moment.duration(timeDifference).asDays();
    const adjustedDateDifference = dateDifference - timeDifferenceInDays;
    return adjustedDateDifference;
  }, [currentData]);

  const handleCancel = () => {
    setModal((prev) => ({
      ...prev,
      cancel: true,
    }));
  };

  const handleSupport = () => {};

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center gap-2 text-textPrimary mb-[10px]">
        <img src={walletIcon} alt="" />
        <span className="text-xl font-semibold text-textPrimary mr-2 dark:text-neutral-200">
          Chi tiết thanh toán
        </span>
      </div>

      <div className="space-y-[9px]">
        <div className="flex flex-row items-center justify-between text-textPrimary dark:text-neutral-200">
          <span className="font-normal">Đơn giá thuê</span>
          <span className="font-normal">
            {formatCurrency(currentPrice)}/ngày
          </span>
        </div>
        {/* <div className="flex flex-row items-center justify-between text-textSecondary">
          <div className="flex flex-row gap-2 items-center">
            <span className="font-normal">Phí dịch vụ</span>
            <InformationCircleIcon className="w-4 h-4" />
          </div>
          <span className="font-normal">{formatCurrency(77000)}/ngày</span>
        </div> */}
      </div>

      <hr className="color-[#EBEBF0]" />

      <div className="space-y-[9px]">
        <div className="flex flex-row items-center justify-between text-textPrimary dark:text-neutral-200">
          <span className="font-normal">Tổng phí thuê xe</span>
          <span className="font-normal">
            {formatCurrency(currentPrice)} x {Math.ceil(countDate) || 1} ngày
          </span>
        </div>
        <div className="flex flex-row items-center justify-between text-textSecondary">
          <div className="flex flex-row gap-2 items-center">
            <span className="font-normal">Bảo hiểm mặc định</span>
            {/* <InformationCircleIcon className="w-4 h-4" /> */}
          </div>
          <span className="font-normal">{formatCurrency(30000)}/ngày</span>
        </div>
        <div className="flex flex-row items-center justify-between text-textSecondary">
          <div className="flex flex-row gap-2 items-center">
            <span className="font-normal">Bảo hiểm tuỳ chọn</span>
            {/* <InformationCircleIcon className="w-4 h-4" /> */}
          </div>
          <span className="font-normal">{formatCurrency(30000)}/ngày</span>
        </div>
        {/* <div className="flex flex-row items-center justify-between text-textSecondary">
          <div className="flex flex-row gap-2 items-center">
            <span className="font-normal">Ưu đãi</span>
            <InformationCircleIcon className="w-4 h-4" />
          </div>
          <span className="font-normal">-{formatCurrency(40000)}</span>
        </div> */}
      </div>

      <hr className="color-[#EBEBF0]" />

      <div className="space-y-[9px]">
        <div className="flex flex-row items-center justify-between text-textPrimary dark:text-neutral-200">
          <span className="font-normal">Thành tiền</span>
          <span className="font-normal">
            {formatCurrency(currentPrice * Math.ceil(countDate))}
          </span>
        </div>
        <div className="flex flex-row items-center justify-between text-textPrimary dark:text-neutral-200">
          <div className="flex flex-row gap-2 items-center">
            <span className="font-normal">Tiền cọc thế chấp</span>
            {/* <InformationCircleIcon className="w-4 h-4" /> */}
          </div>
          <span className="font-normal">{formatCurrency(2000000)}/ngày</span>
        </div>
      </div>

      <hr className="color-[#EBEBF0]" />

      <div className="flex flex-row items-center justify-between text-textPrimary text-[20px] my-[10px] dark:text-neutral-200">
        <span className="font-semibold">Tổng thanh toán</span>
        <span className="font-semibold text-colorPrimary">
          {formatCurrency(
            currentPrice * (Math.ceil(countDate) || 1) + 2000000 + 60000
          )}
        </span>
      </div>

      <div className="flex flex-row items-start mb-[10px]">
        <CheckCircleIcon className="w-7 h-7 text-colorPrimary" />
        <span className="text-textSecondary font-normal ml-[10px]">
          Tôi đồng ý với{" "}
          <button
            className="text-colorPrimary underline"
            onClick={() =>
              setModal((prev) => ({
                ...prev,
                PolicyCancel: {
                  type: 1,
                  isShow: true,
                },
              }))
            }
          >
            Chính sách hủy chuyến
          </button>{" "}
          &{" "}
          <button
            className="text-colorPrimary underline"
            onClick={() =>
              setModal((prev) => ({
                ...prev,
                PolicyCancel: {
                  type: 2,
                  isShow: true,
                },
              }))
            }
          >
            Chi phí phát sinh
          </button>
        </span>
      </div>

      {renderButton({
        type,
        user,
        handleCheckout,
        setModal,
        handleCancel,
        handleSupport,
      })}
    </div>
  );
};

export default FormPay;
