const Radio = ({
  name,
  id,
  label,
  checked,
  onChange,
  image,
}: {
  name: string;
  id: string;
  label: string;
  checked?: boolean;
  image?: string;
  onChange: (value: any) => void;
}) => {
  return (
    <div className="flex items-center">
      <input
        onChange={() => onChange(id)}
        defaultChecked={checked}
        id={id}
        name={name}
        type="radio"
        className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
      />
      <div className="flex flex-col items-center">
        <img
          alt={name}
          hidden={!image}
          src={image}
          className="w-20 h-5 object-cover rounded-full"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block font-normal text-textPrimary dark:text-neutral-300 min-w-[80px] text-[13px] md:text-base"
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default Radio;
