import React, { Fragment } from "react";
import { FC } from "react";
import DatePicker from "react-datepicker";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import moment from "moment";

export interface RentalCarDatesRangeInputProps {
  className?: string;
  dateSelected?: {
    startDate: Date;
    endDate: Date;
  };
  setDateSelected?: React.Dispatch<
    React.SetStateAction<{
      startDate: Date;
      endDate: Date;
    }>
  >;
}

const RentalCarDatesRangeInput: FC<RentalCarDatesRangeInputProps> = ({
  className = "",
  dateSelected,
  setDateSelected,
}) => {
  const { startDate = new Date(), endDate = new Date() } = dateSelected;

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates;

    setDateSelected((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-textSecondary">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left space-y-[10px]">
          <span className="text-textPrimary block xl:text-xl font-semibold dark:text-neutral-200">
            Thời gian thuê xe
          </span>
          <span className="text-base">
            {dateSelected.startDate &&
              moment(dateSelected.startDate).format("DD/MM/yyyy")}
            {dateSelected.endDate &&
              " - " + moment(dateSelected.endDate).format("DD/MM/yyyy")}
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <Popover className={`RentalCarDatesRangeInput flex w-full ${className}`}>
        {({ open }) => (
          <>
            <div
              className={`flex-1 flex items-center focus:outline-none rounded-2xl ${
                open ? "shadow-lg" : ""
              }`}
            >
              <Popover.Button
                className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none `}
              >
                {renderInput()}

                {startDate && open && (
                  <ClearDataButton onClick={() => onChangeDate([null, null])} />
                )}
              </Popover.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 mt-3 top-full left-0 xl:-left-10 w-fit lg:min-w-[800px] sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                  <DatePicker
                    className="z-50"
                    selected={startDate}
                    onChange={onChangeDate}
                    startDate={startDate}
                    endDate={endDate}
                    // dateFormat="MMMM d, yyyy h:mm aa"
                    selectsRange
                    minDate={new Date()}
                    inline
                    monthsShown={2}
                    showPopperArrow={false}
                    renderCustomHeader={(p) => (
                      <DatePickerCustomHeaderTwoMonth {...p} />
                    )}
                    renderDayContents={(day, date) => (
                      <DatePickerCustomDay dayOfMonth={day} date={date} />
                    )}
                  />
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default RentalCarDatesRangeInput;
