import LoadingWithLogo from "components/LoadingWithLogo";
import React, { useEffect } from "react";

const PageGoogleLogin = () => {
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === "startGoogleLogin") {
        const url = process.env.REACT_APP_LOGIN_GOOGLE;
        window.location.href = url;
      }
    };

    // Lắng nghe thông điệp từ cửa sổ chính
    window.addEventListener("message", handleMessage);

    // Hủy listener khi component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="w-full flex items-center justify-center min-h-[700px]">
      <LoadingWithLogo />
    </div>
  );
};

export default PageGoogleLogin;
