import React from "react";
import { toast } from "react-toastify";
import toastErrorImage from "images/toast/error.svg";
import toastInfoImage from "images/toast/info.svg";
import LazyImage from "shared/LazyImage/LazyImage";

interface CustomToastProps {
  type?: number;
  message?: string;
  image?: any;
}

export enum CustomToastType {
  SUCCESS = 1,
  ERROR,
  INFO,
}

export const showCustomToast = ({ type, message, image }: CustomToastProps) => {
  const getImage = (toastType?: number) => {
    switch (toastType) {
      case CustomToastType.ERROR:
        return toastErrorImage;
      case CustomToastType.INFO:
        return toastInfoImage;
      default:
        return image || toastInfoImage;
    }
  };

  let notification = (
    <div className="flex align-center gap-2" style={{ maxWidth: 260 }}>
      <LazyImage src={getImage(type)} style={{ width: 26, height: 26 }} />
      <span className="font-base body-medium-regular">{message}</span>
    </div>
  );

  switch (type) {
    case CustomToastType.SUCCESS:
      return toast(notification);
    case CustomToastType.ERROR:
      return toast(notification);
    case CustomToastType.INFO:
      return toast(notification);
    default:
      return toast(notification);
  }
};
