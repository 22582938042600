import React, { FC } from "react";
import rightImgPng from "images/doublePhone.svg";
import Title from "components/Title";

import CHplayImage from "images/chplay.png";
import ZaloImage from "images/zalo.png";
import AppleImage from "images/appstore.png";
import { EllipseRight } from "images/svg";
import btnChplay from "images/chplay.svg";
import btnZalo from "images/zalo.svg";
import btnApple from "images/appstore.svg";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import LazyImage from "shared/LazyImage/LazyImage";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className="nc-SectionOurFeatures relative py-14 lg:py-0"
      data-nc-id="SectionOurFeatures"
    >
      {/* ellipse */}
      <EllipseRight className="hidden md:flex absolute right-0 top-0" />

      <div
        className={` flex flex-col items-center justify-around container ${
          type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
      >
        <div className="flex-grow max-w-[450px] z-10">
          <LazyImage
            alt=""
            src={rightImg}
            className="z-10 min-h-[585px]"
            width={"100%"}
            height={"100%"}
          />
        </div>
        <div
          className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-3/5 gap-8 flex flex-col ${
            type === "type1" ? "lg:pl-16" : "lg:pr-16"
          }`}
        >
          <Title className={"text-3xl lg:text-5xl font-bold lg:w-2/3"}>
            Cần thuê ô tô có ngay 3GO
          </Title>

          <span className="text-textPrimary font-normal text-xl lg:text-[22px] leading-[33px] dark:text-neutral-400">
            Thoải mái vi vu khắp nơi với nhiều mẫu xe mới mỗi ngày. 3GO - trung
            gian kết nối, đáp ứng các nhu cầu, hiện thực hóa mong muốn của chủ
            sở hữu xe và người thuê xe
          </span>

          <div className="flex flex-row gap-2 items-center justify-center xl:justify-start mx-auto md:mx-0">
            <button>
              <img alt="" src={CHplayImage} className="dark:hidden" />
              <img alt="" src={btnChplay} className="hidden dark:block" />
            </button>
            <button>
              <img alt="" src={AppleImage} className="dark:hidden" />
              <img alt="" src={btnApple} className="hidden dark:block" />
            </button>
            <button>
              <img alt="" src={ZaloImage} className="dark:hidden" />
              <img alt="" src={btnZalo} className="hidden dark:block" />
            </button>
          </div>

          {/* <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge name="Advertising" />
            <span className="block text-xl font-semibold">
              Cost-effective advertising
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              With a free listing, you can advertise your rental with no upfront
              costs
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name="Exposure " />
            <span className="block text-xl font-semibold">
              Reach millions with 3GO
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Millions of people are searching for unique places to stay around
              the world
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="red" name="Secure" />
            <span className="block text-xl font-semibold">
              Secure and simple
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              A Holiday Lettings listing gives you a secure and easy way to take
              bookings and payments online
            </span>
          </li>
        </ul> */}
        </div>
      </div>
      <BgGlassmorphism />
    </div>
  );
};

export default SectionOurFeatures;
