import { useMount } from "ahooks";
import {
  StorageKey,
  getDataFromStorage,
  setDataToStorage,
} from "services/storage";
import { useRecoilState } from "recoil";
import { WishlistProps, wishlistAtom, wishlistNewsAtom } from "./wishlist";
import { showCustomToast } from "shared/toast";
import { useUser } from "atom/user/useUser";

export const useWishlist = () => {
  const [wishlist, setWishlist] = useRecoilState<WishlistProps>(wishlistAtom);
  const [wishlistNews, setWishlistNews] =
    useRecoilState<WishlistProps>(wishlistNewsAtom);
  const { token, user } = useUser();

  const isWishlist = (id) => {
    if (!user || !token) return false;
    return wishlist.data?.hasOwnProperty(id) && wishlist?.data?.[id];
  };

  const isWishlistNews = (id) =>
    wishlistNews.data?.hasOwnProperty(id) && wishlistNews?.data?.[id];

  const toggleWishlist = (item) => {
    if (!token)
      return showCustomToast({ type: 2, message: "Bạn chưa đăng nhập!" });
    if (isWishlist(item?.xid)) {
      let newWishlistData = { ...wishlist.data, [item?.xid]: null };
      let newWishlist = {
        ...wishlist,
        data: Object.fromEntries(
          Object.entries(newWishlistData).filter(
            ([key, value]) => value !== null
          )
        ),
      };
      setWishlist(newWishlist);
      setDataToStorage(StorageKey.WishList, newWishlist);
      showCustomToast({ type: 2, message: "Loại khỏi danh sách yêu thích!" });
    } else {
      let newWishlist = {
        ...wishlist,
        data: { ...wishlist.data, [item?.xid]: item },
      };
      setWishlist(newWishlist);
      setDataToStorage(StorageKey.WishList, newWishlist);
      showCustomToast({ type: 1, message: "Thêm vào danh sách yêu thích!" });
    }
  };

  const toggleWishlistNews = (item) => {
    if (!token)
      return showCustomToast({ type: 2, message: "Bạn chưa đăng nhập!" });
    if (isWishlistNews(item?.xid)) {
      let newWishlistNewsData = { ...wishlistNews.data, [item?.xid]: null };
      let newWishlistNews = {
        ...wishlistNews,
        data: Object.fromEntries(
          Object.entries(newWishlistNewsData).filter(
            ([key, value]) => value !== null
          )
        ),
      };
      setWishlistNews(newWishlistNews);
      setDataToStorage(StorageKey.WishListNews, newWishlistNews);
      showCustomToast({ type: 2, message: "Loại khỏi tin yêu thích!" });
    } else {
      let newWishlistNews = {
        ...wishlistNews,
        data: { ...wishlistNews.data, [item?.xid]: item },
      };
      setWishlistNews(newWishlistNews);
      setDataToStorage(StorageKey.WishListNews, newWishlistNews);
      showCustomToast({ type: 1, message: "Thêm vào tin yêu thích!" });
    }
  };

  useMount(() => {
    if (!wishlist.isCalledApi) {
      getDataFromStorage(StorageKey.WishList).then((res: any) => {
        setWishlist({ data: res?.data, isCalledApi: true });
      });
    }
    if (!wishlistNews.isCalledApi) {
      getDataFromStorage(StorageKey.WishListNews).then((res: any) => {
        setWishlistNews({ data: res?.data, isCalledApi: true });
      });
    }
  });

  return {
    wishlist,
    wishlistNews,
    isWishlist,
    isWishlistNews,
    toggleWishlist,
    toggleWishlistNews,
    setWishlist,
    setWishlistNews,
  };
};
