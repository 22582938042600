import { useUser } from "atom/user/useUser";
import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaUpdateProfile } from "schema/schemaUpdateUser";
import { useUploadFile } from "hooks/useUploadFile";
import { useAddress } from "atom/address/useAddress";
import { showCustomToast } from "shared/toast";
import moment from "moment";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const {
    user: { user },
    requestUpdateInfoUser,
  } = useUser();
  const { requestUploadFile } = useUploadFile();
  const { provinceData, districtData, provinceSelected, setProvinceSelected } =
    useAddress();

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentAvatar, setCurrentAvatar] = useState(user?.profile_image_url);
  const [file, setFile] = useState(null);
  const [address, setAddress] = useState(user?.address);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    // mode: "onSubmit",
    resolver: yupResolver(schemaUpdateProfile),
    defaultValues: {
      name: user?.name || "",
      phone: user?.phone || "",
      email: user?.email || "",
      // address: user?.address || "",
      gender: user?.gender || "",
      date_of_birth: user?.date_of_birth || "",
    },
  });

  useEffect(() => {
    setValue("name", user?.name || "");
    setValue("phone", "0" + user?.phone?.slice(2) || "");
    setValue("email", user?.email || "");
    // setValue("address", user?.address || "");
    setValue("gender", user?.gender || "");
    setValue("date_of_birth", user?.date_of_birth || "");
    setCurrentAvatar(user?.profile_image_zalo_url || user?.profile_image_url);
    setAddress(user?.address);
  }, [user, setValue]);

  const onSubmit = async (data: any) => {
    if (data?.phone?.startsWith("0")) {
      data.phone = "84" + data.phone.slice(1);
    }
    if (!data?.province)
      return showCustomToast({
        type: 2,
        message: "Vui lòng chọn tỉnh/thành phố",
      });
    if (!data?.district)
      return showCustomToast({
        type: 2,
        message: "Vui lòng chọn quận/huyện",
      });

    const newAddress = `${data?.address} ${data?.district} ${data?.province}`;
    try {
      setIsLoading(true);
      let image = null;
      if (file) {
        let formData = new FormData();
        formData.append("image", file);
        formData.append("folder", "prizes");
        const res: any = await requestUploadFile.runAsync(formData);

        if (!res?.data?.file_url)
          return showCustomToast({
            type: 2,
            message: "Cập nhật ảnh đại diện thất bại",
          });

        image = res?.data?.file_url;
      }

      // return console.log(data, provinceSelected);

      await requestUpdateInfoUser
        .runAsync({
          // ...user,
          ...data,
          profile_image_url: image,
          date_of_birth: moment(data?.date_of_birth),
          address: newAddress,
        })
        .then(() => {
          showCustomToast({ type: 1, message: "Cập nhật thành công" });
          setIsEdit(false);
          setAddress(newAddress);
          // reset();
        });
    } catch (error) {
      showCustomToast({ type: 1, message: "Cập nhật thất bại" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeAvatar = (e) => {
    // convert to base64
    try {
      const file = e.target.files[0];
      setFile(file);
      setIsEdit(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setCurrentAvatar(reader.result as string);
      };
    } catch (error) {}
  };

  const handleVerifyPhone = () => {
    // console.log(123);
  };

  // console.log(user);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <div className="space-y-6 sm:space-y-8">
        <div className="flex flex-col md:flex-row">
          <div className="flex-shrink-0 flex items-start">
            <div className="relative rounded-full overflow-hidden flex">
              <Avatar sizeClass="w-32 h-32" imgUrl={currentAvatar} />
              <div className="relative group">
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer opacity-0 hover:opacity-55 transition-opacity duration-300">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="mt-1 text-xs">Đổi ảnh đại diện</span>
                </div>
              </div>

              <input
                type="file"
                className="absolute inset-0 opacity-0 cursor-pointer"
                accept=".jpg, .jpeg, .png"
                // onChange={handleChangeAvatar}
              />
            </div>
          </div>
          <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <Label>Họ Tên</Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className="mt-1.5"
                      {...field}
                      disabled={!isEdit}
                      error={errors?.name?.message}
                    />
                  )}
                />
              </div>
              <div>
                <Label>Ngày sinh</Label>
                <Controller
                  name="date_of_birth"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className="mt-1.5"
                      type="date"
                      {...field}
                      disabled={!isEdit}
                      error={errors?.name?.message}
                    />
                  )}
                />
              </div>
              <div>
                <Label>Giới tính</Label>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <Select className="mt-1.5" {...field} disabled={!isEdit}>
                      <option value="Nam">Nam</option>
                      <option value="Nữ">Nữ</option>
                    </Select>
                  )}
                />
              </div>
              <div>
                <Label>
                  Số điện thoại
                  <span className="ml-1 italic text-neutral-600">
                    {user?.ekyc_status === "verify" ? "(Chưa xác thực)" : ""}
                  </span>
                </Label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className="mt-1.5"
                      {...field}
                      disabled
                      error={errors?.phone?.message}
                      // icon={
                      //   <button
                      //     className="p-1 px-2 bg-green-500 hover:bg-green-600 text-xs rounded-md"
                      //     onClick={handleVerifyPhone}
                      //   >
                      //     Xác thực ngay
                      //   </button>
                      // }
                    />
                  )}
                />
              </div>
              <div>
                <Label>Email</Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className="mt-1.5"
                      {...field}
                      disabled={!isEdit}
                      error={errors?.email?.message}
                    />
                  )}
                />
              </div>
              <div>
                <Label>Địa chỉ</Label>

                {isEdit && (
                  <div className="flex flex-row items-center gap-2">
                    <Controller
                      name="provice"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="mt-1.5"
                          {...field}
                          disabled={!isEdit}
                          onChange={(e) => {
                            setProvinceSelected(JSON.parse(e.target.value)?.id);
                            setValue(
                              "province",
                              JSON.parse(e.target.value)?.title
                            );
                          }}
                          // value={provinceSelected}
                        >
                          <option value="">Tỉnh thành</option>
                          {provinceData?.map((item, index) => (
                            <option value={JSON.stringify(item)} key={index}>
                              {item?.title}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    <Controller
                      name="district"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="mt-1.5"
                          {...field}
                          disabled={!isEdit}
                        >
                          <option value="">Quận huyện</option>
                          {districtData?.map((item, index) => (
                            <option value={item?.title} key={index}>
                              {item?.title}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                )}
                <Input
                  key={address + isEdit}
                  className="mt-1.5"
                  defaultValue={isEdit ? null : address}
                  onChange={(e) => setValue("address", e.target.value)}
                  disabled={!isEdit}
                  error={errors?.address?.message}
                />
              </div>
              <div className="pt-2 flex flex-row items-center gap-4">
                {isEdit || file ? (
                  <div className="flex flex-row gap-4">
                    <ButtonPrimary loading={isLoading} type="submit">
                      Cập nhật thông tin
                    </ButtonPrimary>
                    <button
                      className="bg-transparent border-2 border-yellow-200 text-yellow-200 hover:bg-none px-5 rounded-full min-w-[200px]"
                      type="button"
                      onClick={() => setIsEdit(false)}
                    >
                      Huỷ
                    </button>
                  </div>
                ) : (
                  <button
                    className="bg-white border-2 border-colorPrimary text-colorPrimary hover:bg-none px-5 py-4 rounded-full"
                    type="button"
                    onClick={() => setIsEdit(true)}
                  >
                    Chỉnh sửa thông tin
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
