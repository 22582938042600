import React, { memo, useEffect, useMemo, useRef } from "react";
import Map, { Marker, NavigationControl } from "react-map-gl";
import { useAddress } from "atom/address/useAddress";
import AnyReactComponent from "./AnyReactComponent/AnyReactComponent";

const MapBoxComponent = ({ data = [], center, currentHoverID }) => {
  const { currentAddress } = useAddress();

  const [viewport, setViewport] = React.useState({
    latitude: center?.lat || 0,
    longitude: center?.lng || 0,
    zoom: 12,
    width: "100%",
    height: "100%",
  });

  useEffect(() => {
    if (
      center &&
      typeof center.lat === "number" &&
      typeof center.lng === "number"
    ) {
      setViewport((prev) => ({
        ...prev,
        latitude: center.lat,
        longitude: center.lng,
        zoom: 12,
      }));
    }
  }, [center]);

  const userMarker = useMemo(() => {
    if (currentAddress?.lat && currentAddress?.lng) {
      return (
        <Marker
          key="user"
          color="red"
          latitude={currentAddress.lat}
          longitude={currentAddress.lng}
        ></Marker>
      );
    }
    return null;
  }, [currentAddress]);

  const carMarkers = useMemo(() => {
    return data.map((item) => {
      const { latitude, longitude } = item?.car || {};
      if (typeof latitude === "number" && typeof longitude === "number") {
        return (
          <Marker key={item?.xid} latitude={latitude} longitude={longitude}>
            {/* <Logo /> */}
            <AnyReactComponent
              lat={latitude}
              lng={longitude}
              isSelected={currentHoverID === item?.xid}
              car={item}
            />
          </Marker>
        );
      }
      return null;
    });
  }, [data, currentHoverID]);

  if (
    !center ||
    typeof center.lat !== "number" ||
    typeof center.lng !== "number"
  ) {
    return null;
  }

  return (
    <Map
      key={JSON.stringify(data)}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      initialViewState={{
        longitude: center.lng || 106.70393565686314, //default VP 3GO
        latitude: center.lat || 10.776903478049299,
        zoom: 12,
        bearing: 0,
        pitch: 0,
      }}
      // zoom={position?.zoom || 12}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      style={{ width: "100%", height: "100%" }}
      // key={JSON.stringify(position)}
    >
      {userMarker}
      {carMarkers}
    </Map>
  );
};

export default MapBoxComponent;
