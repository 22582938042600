import { atom } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

export type BannerDataType = {
  link_banner: string | null;
  name: string;
  name_banner: string | null;
  uid: string;
  url: string;
};

export interface BannerProps {
  data: BannerDataType[] | [];
  isCalledApi: boolean;
}

export const bannerAtom = atom<BannerProps>({
  key: "BANNER",
  default: {
    data: [],
    isCalledApi: false,
  },
  effects_UNSTABLE,
});
