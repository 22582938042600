import { useRecoilState } from "recoil";
import { SettingProps, settingAtom } from "./setting";

export const useSetting = () => {
  const [setting, setSetting] = useRecoilState<SettingProps>(settingAtom);

  const setHideBottomBar = (value: boolean) => {
    setSetting({ ...setting, hideBottomBar: value });
  };

  const setShowImageGalleryModal = ({ show, images }) => {
    setSetting({
      ...setting,
      imageGalleryModal: {
        show,
        images,
      },
    });
  };

  const setShowImageGalleryPreview = ({ show, index, images }) => {
    setSetting({
      ...setting,
      imageGalleryPreview: {
        show,
        index,
        images,
      },
    });
  };

  const { hideBottomBar } = setting;

  return {
    setting,
    setSetting,
    hideBottomBar,
    setHideBottomBar,
    setShowImageGalleryModal,
    setShowImageGalleryPreview,
  };
};
