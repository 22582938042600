import { useAsyncEffect, useRequest } from "ahooks";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  forgotPasswordApi,
  getOtpApi,
  getProfileApi,
  refreshTokenApi,
  sendOtpApi,
  signUpZaloApi,
  updateInfoUserApi,
} from "services/api/authApi";
import {
  TokenProps,
  UserInfoProps,
  UserProps,
  userAtom,
  userInfoSelector,
  userTokenSelector,
  userfcmTokenSelector,
} from "./user";
import { removeDataFromStorage } from "services/storage";
import {
  getItemFromStore,
  ITEM_STORE_KEY,
  setItemToStore,
} from "helpers/utils";

export const useUser = () => {
  const [user, setUser] = useRecoilState<UserInfoProps>(userAtom);
  const token =
    useRecoilValue<TokenProps>(userTokenSelector) ||
    getItemFromStore(ITEM_STORE_KEY.USER_TOKEN);
  const fcmToken = useRecoilValue<TokenProps>(userfcmTokenSelector);
  const userInfo = useRecoilValue<UserProps | null>(userInfoSelector);

  const requestUpdateInfoUser = useRequest(
    (data) => updateInfoUserApi(data, token),
    {
      cacheKey: "update_info",
      manual: true,
      onSuccess: (res: any) => {
        setUser((prev) => ({
          ...prev,
          user: res?.data?.user,
        }));
        setItemToStore(
          ITEM_STORE_KEY.USER_DATA,
          res?.data?.user,
          sessionStorage
        );
      },
      onError: (err: any) => console.log("API UPDATE INFO USER: ", err),
    }
  );

  const requestForgotPassword = useRequest(
    (data) =>
      forgotPasswordApi(token, {
        ...data,
        company_id: process.env.REACT_APP_ID_COMPANY,
      }),
    {
      cacheKey: "forgot_password",
      manual: true,
      // onSuccess: (res: any) => {
      //   if (res.data?.status === -117) {
      //   }
      // },
      onError: (err: any) => console.log("API FORGOT PASSWORD: ", err),
    }
  );

  const requestGetOtp = useRequest((phone) => getOtpApi(phone), {
    cacheKey: "get_otp_phone",
    manual: true,
    onSuccess: (res) => console.log(res),
    onError: (err) => console.log(err),
  });

  const requestSendOtp = useRequest(
    (phone, otp) =>
      sendOtpApi({
        otp,
        phone,
        company_id: process.env.REACT_APP_ID_COMPANY,
        type: "zalo",
      }),
    {
      cacheKey: "send_otp_phone",
      manual: true,
      onSuccess: (res) => console.log(res),
      onError: (err) => console.log(err),
    }
  );

  const requestGetProfile = useRequest(
    (access_token = null) => getProfileApi(access_token || token),
    {
      cacheKey: "get_profile",
      manual: true,
      onSuccess: (res: any) => {
        setUser((prev) => ({ ...prev, user: res.data?.user }));
        setItemToStore(
          ITEM_STORE_KEY.USER_DATA,
          res.data?.user,
          sessionStorage
        );
      },

      onError: (err: any) => {
        removeDataFromStorage(ITEM_STORE_KEY.USER_TOKEN);
        removeDataFromStorage(ITEM_STORE_KEY.USER_DATA, sessionStorage);
      },
    }
  );

  const updateUserInfo = ({
    data,
    success,
    error,
  }: {
    data: any;
    success?: (res?: any) => void;
    error?: (err?: any) => void;
  }) => {
    requestUpdateInfoUser
      .runAsync(data)
      .then((res: any) => {
        setUser((prev) => ({
          ...prev,
          user: res?.data?.user,
        }));
        !!success && success(res);
      })
      .catch((err) => !!error && error(err));
  };

  const userLogout = async () => {
    // getItemFromStore(ITEM_STORE_KEY.LOGIN_TYPE) === "zalo" &&
    // await zaloLogout()
    //   .then((res) => console.log(res))
    //   .catch((err) => console.log(err));
    setUser({
      user: null,
      token: null,
      fcmToken: null,
    });
    removeDataFromStorage(ITEM_STORE_KEY.USER_TOKEN);
    removeDataFromStorage(ITEM_STORE_KEY.USER_DATA, sessionStorage);
    removeDataFromStorage(ITEM_STORE_KEY.LOGIN_TYPE);
    window.location.href = "/login";
  };

  // const requestLoginZalo = useRequest((data) => loginWithZalo(data), {
  //   manual: true,
  //   onSuccess: (res: any) => {
  //     console.log(res);
  //     // setUser((prev) => ({...prev, token: res.data?.token }));
  //     // setItemToStore(ITEM_STORE_KEY.USER_TOKEN, res.data?.token);
  //     // setItemToStore(ITEM_STORE_KEY.USER_DATA, res.data?.user, sessionStorage);
  //   },
  //   onError: (err: any) => {
  //     console.log("API LOGIN WITH ZALO: ", err);
  //     // removeDataFromStorage(ITEM_STORE_KEY.USER_TOKEN);
  //     // removeDataFromStorage(ITEM_STORE_KEY.USER_DATA, sessionStorage);
  //     // window.location.href = "/login";
  //   },
  // });

  const requestLoginZalo = async (data, navigate) => {
    fetch(process.env.REACT_APP_ZALO_ACCESS_TOKEN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        secret_key: process.env.REACT_APP_SECRET_KEY,
      },
      body: data,
    })
      .then((response) => response.json())
      .then(({ access_token, refresh_token }) => {
        if (access_token) {
          fetch("https://graph.zalo.me/v2.0/me?fields=id,name", {
            headers: {
              access_token,
            },
          })
            .then((response) => response.json())
            .then(async ({ id, name }) => {
              await signUpZaloApi({
                id,
                name,
                company_id: process.env.REACT_APP_ID_COMPANY,
              }).then((res: any) => {
                setUser(res.data);
                setItemToStore(ITEM_STORE_KEY.USER_TOKEN, res?.data?.token);
                setItemToStore(
                  ITEM_STORE_KEY.TOKEN_EXPIRES,
                  res?.data?.expires_in
                );
                setItemToStore(ITEM_STORE_KEY.LOGIN_TYPE, "zalo");
                setItemToStore(ITEM_STORE_KEY.USER_TOKEN, res?.data?.token);
                setItemToStore(
                  ITEM_STORE_KEY.USER_DATA,
                  res?.data?.user,
                  sessionStorage
                );
                navigate("/");
              });
            });
          // store the Refresh Token in a secured HTTP only cookie
          let expr = new Date(
            new Date().getTime() + 3 * 30 * 24 * 60 * 60 * 1000
          );
          document.cookie = `zalo_refresh_token=${refresh_token};expires=${expr.toUTCString()};path=/refresh;domain=${
            window.location.hostname
          };secure;HttpOnly`;
          setItemToStore(
            ITEM_STORE_KEY.ZALO_TOKEN,
            access_token,
            sessionStorage
          );
        }
      });
  };

  const requestRefreshToken = useRequest(() => refreshTokenApi(token), {
    manual: true,
    onSuccess: (res: any) => {
      setUser((prev) => ({ ...prev, token: res.data?.token }));
      setItemToStore(ITEM_STORE_KEY.USER_TOKEN, res.data?.token);
      setItemToStore(ITEM_STORE_KEY.USER_DATA, res.data?.user, sessionStorage);
    },
    onError: (err: any) => {
      console.log("API REFRESH TOKEN: ", err);
      removeDataFromStorage(ITEM_STORE_KEY.USER_TOKEN);
      removeDataFromStorage(ITEM_STORE_KEY.USER_DATA, sessionStorage);
      window.location.href = "/login";
    },
  });

  useAsyncEffect(async () => {
    //get profile khi có token, dùng cho cả mini app + native app
    if (token && !userInfo) {
      await requestGetProfile.runAsync();
    }
  }, [token]);

  return {
    user,
    token,
    fcmToken,
    userInfo,
    requestGetProfile,
    requestUpdateInfoUser,
    requestRefreshToken,
    requestForgotPassword,
    requestGetOtp,
    requestSendOtp,
    requestLoginZalo,
    setUser,
    updateUserInfo,
    userLogout,
  };
};
