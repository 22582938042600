import { ITEM_STORE_KEY } from "helpers/utils";

const ID_COMPANY = process.env.REACT_APP_ID;

const user_id = JSON.parse(
  window.sessionStorage.getItem(ITEM_STORE_KEY.USER_DATA)
)?.xid;

export const StorageKey = {
  Language: `Language-${user_id}`,
  Authen: `Authen-${user_id}`,
  DefaultAddress: `DefaultAddress-${user_id}`,
  HistorySearch: `HistorySearch-${user_id}`,
  WishList: `WishList-${user_id}`,
  WishListNews: `WishListNews-${user_id}`,
  Cart: `Cart2-${user_id}`,
  NumberCart: `NumberCart-${user_id}`,
  Phone: `Phone-${user_id}`,
  OrderSuccess: `OrderSuccess-${user_id}`,
  MiniAppVersion: `MiniAppVersion-${user_id}`,
  AttendanceGame: `AttendanceGame-${user_id}`,
  FavoriteApp: `FavoriteApp-${user_id}`,
  DataCheckout: `DataCheckout-${user_id}`,
};

export const getDataFromStorage = async (key) => {
  const storedData = await JSON.parse(localStorage.getItem(`${key}`) as any);
  return storedData;
};

export const setDataToStorage = async (key, data) => {
  await localStorage.setItem(`${key}`, JSON.stringify(data));
};

export const removeDataFromStorage = async (key, storage = localStorage) => {
  await storage.removeItem(`${key}`);
};
