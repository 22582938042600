import React, { useImperativeHandle, useState } from "react";
import "./style.css";
import LoadingWithLogo from "components/LoadingWithLogo";

export const globalLoadingRef = React.createRef<any>();

export const globalLoading = {
  show: () => {
    globalLoadingRef?.current?.show();
  },
  hide: () => {
    globalLoadingRef?.current?.hide();
  },
};

export const GlobalLoading = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const show = () => {
    setLoading(true);
  };

  const hide = () => {
    setLoading(false);
  };

  useImperativeHandle(ref, () => {
    return { show: show, hide: hide };
  });

  return loading ? (
    <>
      <div className="overlay z-10">
        <div className="flex justify-center items-center w-screen h-screen z-10">
          <LoadingWithLogo />
        </div>
      </div>
    </>
  ) : (
    <></>
  );
});
