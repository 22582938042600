import { useAsyncEffect, useRequest } from "ahooks";
import { useRecoilState } from "recoil";
import { useWarehouse } from "atom/warehouse/useWarehouse";
import {
  getCarById,
  getCarBySlugApi,
  getCarsByPageApi,
  getCarsFlashsaleByPageApi,
  getCarsNearByPageApi,
  getCarsNewManufactureByPageApi,
} from "services/api/carApi";
import {
  carAtom,
  carFlashsaleAtom,
  carNearAtom,
  carNewManufactureAtom,
} from "./car";
import { useAddress } from "atom/address/useAddress";

export const useCar = () => {
  const { warehouseId }: any = useWarehouse();
  const { currentAddress } = useAddress();

  const [car, setCar] = useRecoilState(carAtom);
  const [carFlashsale, setCarFlashsale] = useRecoilState(carFlashsaleAtom);
  const [carNear, setCarNear] = useRecoilState(carNearAtom);
  const [carNewManufacture, setCarNewManufacture] = useRecoilState(
    carNewManufactureAtom
  );

  const requestGetCarsByPage = useRequest(
    (offset = 0, limit = 8) => getCarsByPageApi(warehouseId, offset, limit),
    {
      cacheKey: "get-cars-by-page",
      manual: true,
      onSuccess: (res: any) => {
        setCar({
          meta: res.meta,
          data: res.data,
          isCalledApi: true,
          noMore: res.data.length >= res?.meta?.paging?.total,
        });
      },
      onError: (err: any) => console.log("API GET CARS BY PAGE", err),
    }
  );
  const requestGetCarsFlashsaleByPage = useRequest(
    (offset = 0, limit = 8) =>
      getCarsFlashsaleByPageApi(warehouseId, offset, limit),
    {
      cacheKey: "get-cars-flashsale-by-page",
      manual: true,
      onError: (err: any) => console.log("API GET CARS FLASHSALE BY PAGE", err),
    }
  );
  const requestGetCarsNearByPage = useRequest(
    ({
      lat = currentAddress?.lat,
      lng = currentAddress?.lng,
      offset = 0,
      limit = 8,
    }) => getCarsNearByPageApi(warehouseId, offset, limit, lat, lng),
    {
      cacheKey: "get-cars-nears-by-page",
      manual: true,
      onError: (err: any) => console.log("API GET CARS NEAR BY PAGE", err),
    }
  );
  const requestGetCarsNewManufactureByPage = useRequest(
    (offset = 0, limit = 8) =>
      getCarsNewManufactureByPageApi(warehouseId, offset, limit),
    {
      cacheKey: "get-cars-new-manufacture-by-page",
      manual: true,
      onError: (err: any) =>
        console.log("API GET CARS  NEW MANUFACTURE BY PAGE", err),
    }
  );

  const requestGetCar = useRequest(
    (slug: string) => getCarBySlugApi(warehouseId, slug),
    {
      cacheKey: "get-car-by-slug",
      manual: true,
      onError: (err: any) => console.log("API GET CAR BY ID", err.message),
    }
  );

  useAsyncEffect(async () => {
    if (warehouseId && !car.isCalledApi) {
      requestGetCarsByPage.runAsync().then((res: any) =>
        setCar({
          meta: res.meta,
          data: res.data,
          isCalledApi: true,
          noMore: res.data.length >= res?.meta?.paging?.total,
        })
      );
    }
    if (warehouseId && !carFlashsale.isCalledApi) {
      requestGetCarsFlashsaleByPage.runAsync().then((res: any) =>
        setCarFlashsale({
          data: res.data,
          isCalledApi: true,
          noMore: res.data.length >= res?.meta?.paging?.total,
        })
      );
    }
    if (warehouseId && !carNewManufacture.isCalledApi) {
      requestGetCarsNewManufactureByPage.runAsync().then((res: any) =>
        setCarNewManufacture({
          data: res.data,
          isCalledApi: true,
          noMore: res.data.length >= res?.meta?.paging?.total,
        })
      );
    }
    if (
      warehouseId &&
      !carNear.isCalledApi &&
      currentAddress?.lat &&
      currentAddress?.lng
    ) {
      requestGetCarsNearByPage
        .runAsync({ lat: currentAddress?.lat, lng: currentAddress?.lng })
        .then((res: any) =>
          setCarNear({
            data: res.data,
            isCalledApi: true,
            noMore: res.data.length >= res?.meta?.paging?.total,
          })
        );
    }
  }, [warehouseId, currentAddress]);

  return {
    car,
    carNear,
    carFlashsale,
    carNewManufacture,
    requestGetCar,
    requestGetCarsByPage,
    requestGetCarsFlashsaleByPage,
    requestGetCarsNewManufactureByPage,
    requestGetCarsNearByPage,
    setCar,
    setCarNear,
    setCarFlashsale,
    setCarNewManufacture,
  };
};
