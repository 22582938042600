import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import { DataProps } from "atom/news/news";

export interface Card3SmallProps {
  className?: string;
  newsItem: DataProps;
}

const CardNewsSmall: FC<Card3SmallProps> = ({
  className = "h-full",
  newsItem,
}) => {
  return (
    <div
      className={`nc-Card3Small relative flex flex-col-reverse sm:flex-row sm:justify-between sm:items-center ${className}`}
      data-nc-id="Card3Small"
    >
      <Link
        to={newsItem?.slug as string}
        className=" absolute inset-0"
        title={newsItem?.title as string}
      ></Link>
      <div className="relative space-y-2">
        {/* <PostCardMeta meta={{ ...post }} /> */}
        <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <Link
            to={newsItem?.slug as string}
            className=" line-clamp-2"
            title={newsItem?.title as string}
          >
            {newsItem?.title}
          </Link>
        </h2>
      </div>

      <Link
        to={newsItem?.slug}
        title={newsItem?.title as string}
        className={`block sm:w-20 flex-shrink-0 relative rounded-lg overflow-hidden mb-5 sm:ml-4 sm:mb-0 group`}
      >
        <div className={`w-full h-0 aspect-w-16 aspect-h-9 sm:aspect-h-16`}>
          <NcImage
            containerClassName="absolute inset-0"
            className="nc-will-change-transform object-cover w-full h-full group-hover:scale-110 transform transition-transform duration-300"
            src={newsItem?.image_url as string}
            title={newsItem?.title as string}
          />
        </div>
      </Link>
    </div>
  );
};

export default CardNewsSmall;
