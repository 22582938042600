import { ApiUtils } from ".";
import { PATH } from "./path";

export const getCategoryNewsApi = async () => {
  return await ApiUtils.request(PATH.get_category_news);
};

export const getListNewsApi = async (offset, limit) => {
  const URL = PATH.get_list_news + `&offset=${offset}` + `&limit=${limit}`;
  return await ApiUtils.request(URL);
};

export const getNewsBySlugApi = async (offset, limit, slug) => {
  const URL =
    PATH.get_list_news +
    `&offset=${offset}` +
    `&limit=${limit}` +
    `&filters=slug%20lk%20%22${slug}%22`;
  return await ApiUtils.request(URL);
};

export const getNewsByCategoryApi = async (
  categoryId: string,
  offset: number,
  limit: number
) => {
  const URL =
    PATH.get_list_news +
    `&offset=${offset}` +
    `&limit=${limit}` +
    `&filters=news_category_id%20eq%20%22${categoryId}%22%20&hashable=${categoryId}`;
  return await ApiUtils.request(URL);
};
