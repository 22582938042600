"use client";

import React, { Fragment, memo } from "react";
import { FC } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";

import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import ClearDataButton from "../ClearDataButton";
import { useFilter } from "atom/filter/useFilter";
import classNames from "classnames";

export interface ExperiencesDateSingleInputProps {
  className?: string;
  fieldClassName?: string;
  title?: string;
  start?: boolean;
  data?: {
    tab: string;
    address: {
      id: number;
      title: string;
    };
    startDate: string | null;
    endDate: string | null;
  };
  setData?: React.Dispatch<
    React.SetStateAction<{
      tab: string;
      address: {
        id: number;
        title: string;
      };
      startDate: string | null;
      endDate: string | null;
    }>
  >;
}

const ExperiencesDateSingleInput: FC<ExperiencesDateSingleInputProps> = ({
  className = "",
  fieldClassName = "[ nc-hero-field-padding]",
  title = "Ngày thuê",
  start = false,
}) => {
  const { filter, setFilter } = useFilter();

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400 px-3">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span
            className={classNames(
              "block xl:text-lg font-semibold",
              !start && !filter?.date?.startDate && "text-neutral-500"
            )}
          >
            {title}
          </span>
          <span className="block mt-1 text-base text-neutral-400 leading-none font-light">
            {start
              ? moment(filter?.date?.startDate || new Date()).format(
                  "DD/MM/YYYY"
                )
              : filter?.date?.endDate
              ? moment(filter?.date?.endDate || new Date()).format("DD/MM/YYYY")
              : "Chọn ngày"}
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <Popover
        className={`ExperiencesDateSingleInput relative flex ${className}`}
      >
        {({ open, close }) => (
          <>
            <Popover.Button
              disabled={!start && !filter?.date?.startDate}
              className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none  ${
                open ? "nc-hero-field-focused" : ""
              }`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              {renderInput()}
              {/* {open && <ClearDataButton onClick={() => resetFilter()} />} */}
            </Popover.Button>

            {open && (
              <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
            )}

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl z-20">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                  <DatePicker
                    selected={moment(
                      start
                        ? filter?.date?.startDate || new Date()
                        : filter?.date?.endDate || filter?.date?.startDate
                    ).toDate()}
                    monthsShown={2}
                    showPopperArrow={false}
                    inline
                    minDate={
                      start
                        ? new Date()
                        : moment(filter?.date?.startDate).toDate()
                    }
                    showDisabledMonthNavigation
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        date: start
                          ? {
                              startDate: moment(e).format("l"),
                              endDate: prev?.date?.endDate,
                            }
                          : {
                              startDate: prev?.date?.startDate,
                              endDate: moment(e).format("l"),
                            },
                      }));
                      close();
                    }}
                    renderCustomHeader={(p) => (
                      <DatePickerCustomHeaderTwoMonth {...p} />
                    )}
                    renderDayContents={(day, date) => (
                      <DatePickerCustomDay dayOfMonth={day} date={date} />
                    )}
                  />
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default memo(ExperiencesDateSingleInput);
