import React, { FC } from "react";

export interface SaleOffBadgeProps {
  className?: string;
  desc?: string;
}

const SaleOffBadge: FC<SaleOffBadgeProps> = ({ className = "", desc }) => {
  return (
    <div
      className={`nc-SaleOffBadge flex items-center justify-center py-0.5 px-3 bg-colorPrimary rounded-tl-md rounded-br-md text-red-50  ${className}`}
      data-nc-id="SaleOffBadge"
    >
      {desc}
    </div>
  );
};

export default SaleOffBadge;
