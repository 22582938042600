import { Dialog, Transition } from "@headlessui/react";
import { Fragment, memo } from "react";

const data = [
  {
    lighlight: "Không tính phí",
    content: "nếu huỷ chuyến trước khi chúng tôi xác nhận chuyến",
  },
  {
    lighlight: "Hoàn cọc và bồi thường 100%",
    content: "nếu chủ xe huỷ chuyến trong vòng 7 ngày trước chuyến đi",
  },
  {
    lighlight: "Không hoàn tiền",
    content: "nếu quý khách hủy chuyến sau khi đã xác nhận chuyến đi",
  },
];

const data2 = [
  {
    lighlight: "Phí vượt giới hạn: 5 000đ/km",
    content:
      "Phụ phí phát sinh nếu di chuyển vượt quá 350km khi thuê xe 1 ngày",
  },
  {
    lighlight: "Phí quá giờ: 80 000đ/giờ",
    content:
      "Phụ phí phát sinh nếu hoàn trả xe trễ giờ. Trường hợp trễ quá 3 giờ phụ phí thêm 1 ngày thuê",
  },
  {
    lighlight: "Phí vệ sinh: 80 000đ",
    content: "Phụ phí phát sinh khi xe hoàn trả không đảm bảo vệ sinh",
  },
];

interface IModalPolicy {
  isOpen: {
    report: boolean;
    cancel: boolean;
    active: boolean;
    PolicyCancel: {
      type: 1 | 2;
      isShow: boolean;
    };
  };
  setIsOpen: React.Dispatch<
    React.SetStateAction<{
      report: boolean;
      cancel: boolean;
      active: boolean;
      PolicyCancel: {
        type: 1 | 2;
        isShow: boolean;
      };
    }>
  >;
}

const ModalPolicyCancel = ({ isOpen, setIsOpen }: IModalPolicy) => {
  return (
    <>
      <Transition appear show={isOpen.PolicyCancel.isShow} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() =>
            setIsOpen((prev) => ({
              ...prev,
              PolicyCancel: {
                type: 1,
                isShow: false,
              },
            }))
          }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-colorSecondary">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-semibold leading-6 text-textPrimary text-center dark:text-neutral-200"
                  >
                    {isOpen.PolicyCancel.type === 1
                      ? "Chính sách hủy chuyến"
                      : "Phụ phí phát sinh"}
                  </Dialog.Title>

                  <div className="mt-5 p-5">
                    <ul className="list-disc list-outside space-y-2">
                      {(isOpen.PolicyCancel.type === 1 ? data : data2)?.map(
                        (item, index) => {
                          return (
                            <li key={index}>
                              <span className="font-semibold text-colorPrimary">
                                {item.lighlight}{" "}
                              </span>
                              <span className="text-textSecondary">
                                {item.content}
                              </span>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default memo(ModalPolicyCancel);
