import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Title from "components/Title";
import { DADVITE_DATA } from "data/home";
import { EllipseRight } from "images/svg";
import { useEffect, useRef, useState } from "react";

const QuizItem = ({
  item,
  endLine,
  show = false,
}: {
  item: {
    question: string;
    answers: string[];
    title?: string[];
    note?: string;
  };
  endLine: boolean;
  show: boolean;
}) => {
  const [isShow, setIsShow] = useState(show);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isShow]);

  return (
    <div>
      <div
        className="flex flex-row items-center justify-between pt-4 md:pt-6 pb-3 md:pb-5 px-5"
        onClick={() => setIsShow(!isShow)}
      >
        <p className="text-xl xl:text-2xl font-normal text-textPrimary dark:text-neutral-300">
          {item?.question}
        </p>
        <button>
          {isShow ? (
            <XMarkIcon className="w-4 text-[#39393B] dark:text-neutral-300" />
          ) : (
            <PlusIcon className="w-4 text-[#39393B] dark:text-neutral-300" />
          )}
        </button>
      </div>
      <div
        className={`overflow-hidden transition-height duration-300 ease-in-out`}
        style={{ height: isShow ? `${contentHeight}px` : "0px" }}
      >
        <div ref={contentRef}>
          <div className="ml-5 text-sm xl:text-lg font-normal text-[#6D758F] flex flex-col">
            {item?.title?.map((item, index) => (
              <span className="pt-3" key={index}>
                {item}
              </span>
            ))}
          </div>
          <ul className="py-3">
            {item?.answers?.map((answer, index) => (
              <li
                style={{
                  listStylePosition: "inside",
                }}
                key={index}
                className={`text-sm xl:text-lg font-normal text-[#6D758F] px-10 list-disc leading-[30.6px]`}
              >
                {answer}
              </li>
            ))}
          </ul>
          {item?.note && (
            <span className="ml-5 text-sm xl:text-lg font-normal italic text-[#6D758F] flex flex-col pb-4">
              Lưu ý: {item?.note}
            </span>
          )}
        </div>
      </div>
      <hr className={endLine ? "hidden" : "w-[97%] mx-auto"} />
    </div>
  );
};

const HomeDadvite = () => {
  return (
    <div className="flex flex-col justify-center items-center py-[30px] md:py-[60px] relative overflow-hidden p-3">
      <BgGlassmorphism />

      <EllipseRight className="hidden md:flex absolute transform right-0 -top-[100px]" />
      <Title
        children="Câu Hỏi Thường Gặp"
        className=" mt-4 mb-5 font-extrabold text-[30px] md:text-[38px]"
      />
      <div className="border border-[#DCE2E8] rounded-md w-full px-1 mx-1 my-10 max-w-[929px] z-10">
        {DADVITE_DATA?.map((item, index) => {
          return (
            <QuizItem
              key={index}
              item={item}
              endLine={DADVITE_DATA?.length - 1 === index}
              show={index === 0}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HomeDadvite;
