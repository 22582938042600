import React, { Fragment, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "atom/user/useUser";
import { REGEX } from "schema/regex";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { showCustomToast } from "shared/toast";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

const ForgotPassPage = () => {
  const navigate = useNavigate();
  const { requestForgotPassword } = useUser();

  const [isShowPass, setIsShowPass] = useState({
    password: false,
    confirmPassword: false,
  });
  const {
    formState: { errors },
    control,
    handleSubmit,
    setError,
  } = useForm({
    resolver: yupResolver(
      yup.object({
        phone: yup
          .string()
          .required("Số điện thoại không được bỏ trống")
          .matches(RegExp(REGEX.NUMBER_PHONE), "Số điện thoại không hợp lệ"),
        password: yup
          .string()
          .matches(
            RegExp(REGEX.PASSWORD),
            "Mật khẩu phải có ít nhất 8 ký tự, bao gồm ít nhất một chữ cái viết thường, một chữ cái viết hoa, một chữ số và một ký tự đặc biệt."
          )
          .required("Vui lòng nhập mật khẩu mới"),
        password_confirmation: yup.string(),
      })
    ),
    defaultValues: {
      phone: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    if (data.password !== data.password_confirmation) {
      setError("password_confirmation", {
        message: "Mật khẩu và nhập lại mật khẩu phải giống nhau",
        type: "error",
      });
      return;
    }
    if (data?.phone?.startsWith("0")) {
      data.phone = "84" + data.phone.slice(1);
    }
    try {
      await requestForgotPassword
        .runAsync({
          phone: data.phone,
          password: data.password,
          password_confirmation: data.password_confirmation,
        })
        .then((res: any) => {
          showCustomToast({ type: 1, message: res?.message });
          if (res?.data?.status === 1) return navigate("/login");
        });
    } catch (error) {
      console.error("Error forgot password:", error);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>3GO | Quên mật khẩu</title>
      </Helmet>

      <BgGlassmorphism />
      <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
        <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
          <h1 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
            Quên mật khẩu
          </h1>
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            Vui lòng nhập số điện thoại của bạn
          </p>
          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="Số điện thoại"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                  error={errors?.phone?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type={!isShowPass.password && "password"}
                  placeholder="Mật khẩu mới"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                  error={errors?.password?.message}
                  icon={
                    <div
                      onClick={() =>
                        setIsShowPass((prev) => ({
                          ...prev,
                          password: !prev.password,
                        }))
                      }
                      className="cursor-pointer"
                    >
                      {isShowPass.password ? (
                        <EyeIcon className="w-5 h-5" />
                      ) : (
                        <EyeSlashIcon className="w-5 h-5" />
                      )}
                    </div>
                  }
                />
              )}
            />
            <Controller
              name="password_confirmation"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type={!isShowPass.confirmPassword && "password"}
                  placeholder="Xác nhận mật khẩu"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                  error={errors?.password_confirmation?.message}
                  icon={
                    <div
                      onClick={() =>
                        setIsShowPass((prev) => ({
                          ...prev,
                          confirmPassword: !prev.confirmPassword,
                        }))
                      }
                      className="cursor-pointer"
                    >
                      {isShowPass.confirmPassword ? (
                        <EyeIcon className="w-5 h-5" />
                      ) : (
                        <EyeSlashIcon className="w-5 h-5" />
                      )}
                    </div>
                  }
                />
              )}
            />

            <ButtonPrimary
              type="submit"
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-400"
              loading={requestForgotPassword.loading}
            >
              Xác nhận
            </ButtonPrimary>
          </form>
          <div className="mt-6 space-y-2 text-center relative">
            <p className="text-gray-600 dark:text-gray-400">
              <Link
                to="/login"
                className="text-blue-600 hover:underline dark:text-blue-400"
              >
                Về trang Đăng nhập
              </Link>
            </p>
            <p className="text-gray-600 dark:text-gray-400">
              <Link
                to="/signup"
                className="text-blue-600 hover:underline dark:text-blue-400"
              >
                Tạo tài khoản mới
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPassPage;
