import { atom } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

interface CarDetailsProps {
  address: string;
  back_image: string | null;
  color: string;
  created_at: string;
  created_by: number;
  description: string;
  features: string;
  front_image: string | null;
  fuel_consumption: number;
  fuel_type: "gasoline" | "diesel" | "electric";
  left_image: string | null;
  license_plate: string;
  manufacture: string;
  model: string;
  purchase_price: number;
  registration_back_image: string | null;
  registration_front_image: string | null;
  right_image: string | null;
  seats: number;
  status: "available" | "rented" | "maintenance" | "inactive";
  transmission: "automatic" | "manual";
  year_of_manufacture: number;
  longitude?: number;
  latitude?: number;
}

export interface CarProps {
  affiliate_setting: any;
  affiliate_setting_id: any;
  car: CarDetailsProps;
  car_id: string | null;
  category: {
    name: string;
    xid: string;
  };
  created_at: string;
  description: string;
  details: {
    sales_price: number;
    sales_tax_type: string;
    x_tax_id: string | null;
  };
  image: string | null;
  image_url: string | null;
  is_active: 1 | 0;
  quantity: number;
  ratings: any[];
  slug: string;
  title: string;
  unit: {
    name: string;
    short_name: string;
    xid: string;
  };
  updated_at: string;
  xid: string;
  promotion?: any[];
}

export interface DataProps {
  data: CarProps[];
  isCalledApi: boolean;
  noMore: boolean;
  meta?: any;
}

export const carAtom = atom<DataProps>({
  key: "CAR",
  default: {
    data: [],
    isCalledApi: false,
    noMore: false,
    meta: null,
  },
  effects_UNSTABLE,
});

export const carFlashsaleAtom = atom<DataProps>({
  key: "CAR-FLASHSALE",
  default: {
    data: [],
    isCalledApi: false,
    noMore: false,
  },
  effects_UNSTABLE,
});

export const carNewManufactureAtom = atom<DataProps>({
  key: "CAR-NEW-MANUFACTURE-FLASHSALE",
  default: {
    data: [],
    isCalledApi: false,
    noMore: false,
  },
  effects_UNSTABLE,
});
export const carNearAtom = atom<DataProps>({
  key: "CAR-NEAR",
  default: {
    data: [],
    isCalledApi: false,
    noMore: false,
  },
  effects_UNSTABLE,
});
