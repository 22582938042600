import React, { FC } from "react";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
  className?: string;
  handleChangePage?: (page: number) => void;
  totalPage?: number;
  currentPage?: number;
}

const Pagination: FC<PaginationProps> = ({
  className = "",
  totalPage,
  handleChangePage,
  currentPage,
}) => {
  if (isNaN(totalPage)) return;

  const renderItem = (_, index: number) => {
    if (index + 1 === currentPage) {
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-600 text-white ${twFocusClass()}`}
        >
          {index + 1}
        </span>
      );
    }
    return (
      <button
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        // to={pag.href}
        onClick={() => handleChangePage(index + 1)}
      >
        {index + 1}
      </button>
    );
  };

  const handlePrevious = () => {
    if (totalPage > 1) {
      if (handleChangePage && currentPage > 1)
        handleChangePage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (totalPage > 1) {
      if (handleChangePage) handleChangePage(currentPage + 1);
    }
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      <button
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        onClick={handlePrevious}
        disabled={currentPage === 1}
      >
        <svg
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {[...Array(totalPage)].map(renderItem)}
      <button
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        onClick={handleNext}
        disabled={currentPage === totalPage}
      >
        <svg
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </nav>
  );
};

export default Pagination;
