export const ADD_ADDRESS = {
  NAME: "name",
  EMAIL: "email",
  PHONE: "phone",
  ADDRESS: "address",
  SHIPPING_ADDRESS: "shipping_address",
  ZIPCODE: "zipcode",
  CITY: "city",
  STATE: "state",
  WARD: "ward",
  COUNTRY: "country",
  TO_DISTRICT_ID: "to_district_id",
  TO_WARD_CODE: "to_ward_code",
};

export const CREATE_BANK = {
  BANKNAME: "bankName",
  BANKNUMBER: "bankNumber",
  OWNER: "owner",
};

export const WITHDRAW = {
  MONEY: "money",
  NOTE: "note",
};

export const BOOKING_COURSE = {
  NAME: "name",
  EMAIL: "email",
  PHONE: "phone",
  ADDRESS: "address",
  CITY: "city",
  STATE: "state",
  AGE: "age",
  COURSE: "course",
  ZIPCODE: "zipcode",
  WARD: "ward",
  TO_DISTRICT_ID: "to_district_id",
};

export const BOOKING_NEWSPAPER = {
  NAME: "name",
  PHONE: "phone",
  EMAIL: "email",
  BIRTHDAY: "birthday",
  CHANNEL: "channel",
  NO: "no",
  STREET: "street",
  WARD: "ward",
  DISTRICT: "district",
  CITY: "city",
  ROLE: "role",
  COMPANY: "company",
  TAX_IDENTIFICATION_NUMBER: "taxIdentificationNumber",
  NEWSPAPER_FINANCIAL: "newspaper_financial",
  NEWSPAPER_WEEKLY: "newspaper_weekly",
  TYPE_NEWSPAPER: "typeNewSpaper",
};

export const ADD_COMMENT = {
  NAME: "name",
  PHONE: "phone",
  EMAIL: "email",
};

export const INFOR_CAR = {
  LICENSE_PLATES: "licensePlates",
  AUTOMAKERS: "automakers",
  MODEL: "model",
  YEAR_OF_MANUFACTURE: "yearOfManufacture",
  SEATS: "seats",
  COLOR: "color",
  TRANSMISSION: "transmission",
  FUEL: "fuel",
  FEATURES: "features",
  FUEL_CONSUMPTION: "fuelConsumption",
  ADDRESS: "address",
  PRICE: "price",
  DESCRIPTION: "description",
  PERSONAL_TAX_CODE: "personalTaxCode",
};

export const USER_REGISTER = {
  EMAIL: "email",
  PASSWORD: "password",
  NAME: "name",
  PHONE: "phone",
  PASSWORD_RETYPE: "password_retype",
};

export const UPDATE_PROFILE = {
  EMAIL: "email",
  ADDRESS: "address",
  NAME: "name",
  PHONE: "phone",
};

export const CHANGE_PASSWORD = {
  OLD_PASSWORD: "oldPassword",
  NEW_PASSWORD: "newPassword",
  NEW_PASSWORD_RETYPE: "newPassword_retype",
};

export const RENTAL_FORM = {
  // Step One
  LICENSE_PLATES: "licensePlateNumber",
  BRAND: "brand",
  MODEL: "model",
  COLOR: "color",
  YEAR: "year",
  NUMBER_OF_SEATS: "numberOfSeats",
  TRANSMISSION: "transmission",
  FUEL: "fuel",
  FEATURES: "features",
  KILOMETER_TRAVELED: "kilometerTraveled",
  FUEL_CONSUMPTION: "fuelConsumption",
  ADDRESS: "address",
  DESCRIPTION_OF_CAR: "descriptionOfCar",
  TAX: "tax",

  // Step Two
  IMAGE_FONT: "imageFont",
  IMAGE_BACK: "imageBack",
  IMAGE_LEFT: "imageLeft",
  IMAGE_RIGHT: "imageRight",

  // Step Three
  STEP_THREE_IMAGE_FONT: "stepThreeImageFont",
  STEP_THREE_IMAGE_BACK: "stepThreeImageBack",

  // Step Four
  PRICE: "price",
  DRIVER_SERVICE_ACTIVE: "driverServiceActive",
  DRIVER_SERVICE_VALUE: "driverServiceValue",
  DISCOUNT_ACTIVE: "discountActive",
  DISCOUNT: "discountValue",
};
