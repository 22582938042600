import { ApiUtils } from "./index";
import { PATH } from "./path";

export const checkoutServiceApi = async (data: any, token: string) => {
  return await ApiUtils.post(PATH.checkout_service, data, { token: token });
};

export const getMyBookingsApi = async (token: string) => {
  return await ApiUtils.request(PATH.my_online_booking, null, { token: token });
};

export const bookingTimeApi = async (
  serviceId: string,
  data: any,
  token: string
) => {
  return await ApiUtils.post(PATH.booking_time + serviceId, data, {
    token: token,
  });
};

export const getCalendarApi = async (
  warehouseId: string,
  date: string, // yyyy-mm-dd
  token: string
) => {
  const URL =
    PATH.get_calendar +
    `&filters=warehouse_id%20eq%20%22${warehouseId}%22%20&hashable=${warehouseId}&date=${date}`;
  return await ApiUtils.request(URL, null, {
    token: token,
  });
};

export const cancelServiceApi = async (bookingId: string, token: string) => {
  return await ApiUtils.post(PATH.cancel_service + bookingId, null, {
    token: token,
  });
};

export const zaloCallBackApi = async (data) => {
  return await ApiUtils.post(PATH.zalo_callback, data);
};
