import { useRecoilState } from "recoil";
import { IRentalProps, rentalAtom, rentalStepAtom } from "./rental";

export const useRental = () => {
  const [register, setRegister] = useRecoilState<IRentalProps>(rentalAtom);
  const [step, setStep] = useRecoilState(rentalStepAtom);

  const setRentalStep = (step) => {
    setStep(step);
  };

  return { register, setRegister, setRentalStep, step };
};
