import { useRecoilState } from "recoil";
import { companyId, companyIdAtom } from "./company";

export const useCompanyId = () => {
  const [companyId, setCompanyId] = useRecoilState<companyId>(companyIdAtom);

  return {
    companyId,
    setCompanyId,
  };
};
