import { atom } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";
import moment from "moment";

export interface BookingCheckoutType {
  car: any;
  date: {
    day: number;
    pickupDate: any;
    returnDate: any;
  };
  hasDriver: boolean;
  promotion: any;
  point: number;
  referralCode: string;
  note: string;
  address: {
    lat?: number;
    lng?: number;
    detail: string;
    type: "hub" | "myLocation";
  };
}

export const bookingAtom = atom({
  key: `BOOKINGS`,
  default: [],
  effects_UNSTABLE,
});

//Atom lưu data check out
export const bookingCheckoutAtom = atom<BookingCheckoutType>({
  key: `BOOKING-CHECKOUT`,
  default: {
    car: null,
    date: {
      day: 1,
      pickupDate: moment().toDate(),
      returnDate: moment().add(1, "days").toDate(),
    },
    hasDriver: false,
    promotion: null,
    point: 0,
    referralCode: "",
    note: "",
    address: {
      detail: "",
      type: "hub",
    },
  },
  effects_UNSTABLE,
});
