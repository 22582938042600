import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React, { FC } from "react";
import WidgetHeading1 from "./WidgetHeading1";
import { useNews } from "atom/news/useNews";
import CardNewsSmall from "./CardNewsSmall";

export interface WidgetPostsProps {
  className?: string;
  posts?: PostDataType[];
}

const widgetPostsDemo: PostDataType[] = DEMO_POSTS.filter(
  (_, i) => i > 2 && i < 7
);

const WidgetPosts: FC<WidgetPostsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  posts = widgetPostsDemo,
}) => {
  const { news } = useNews();
  return (
    <div
      className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className}`}
      data-nc-id="WidgetPosts"
    >
      <WidgetHeading1
        title="🎯 Bài viết thịnh hành"
        // viewAll={{ label: "Xem tất cả", href: "/news" }}
      />
      <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700 md:max-h-[1000px] overflow-auto">
        {news?.data?.map((newsItem) => (
          <CardNewsSmall
            className="p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700"
            key={newsItem.xid}
            newsItem={newsItem}
          />
        ))}
      </div>
    </div>
  );
};

export default WidgetPosts;
