import { atom, selector } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

export interface OAProps {
  oaId: string | null;
  oaType: string | null;
  isActiveFollowOA: boolean;
}
export interface CompanyProps {
  description: string;
  domain: string;
  name: string;
  short_name: string;
  is_active_follow_oa: boolean | number;
  light_logo_url: string;
  small_light_logo_url: string;
  oa_id: string;
  type_oa: string;
  xid: string;
}
export interface WarehouseProps {
  company: CompanyProps;
  company_id: string | number;
  dark_logo: any;
  dark_logo_url: string;
  logo: any;
  logo_url: string;
  name: string;
  online_store_enabled: string | number | boolean;
  slug: string;
  xid: string;
}

export const warehouseAtom = atom<WarehouseProps[]>({
  key: "WAREHOUSE",
  default: [],
  effects_UNSTABLE,
});

export const warehouseIdSelector = selector<string | null>({
  key: "WAREHOUSE_ID",
  get: ({ get }) => {
    const warehouse = get(warehouseAtom);
    if (warehouse) {
      const warehouseId = warehouse[0]?.xid;
      return warehouseId;
    }
    return null;
  },
});

export const OASelector = selector<OAProps | unknown>({
  key: "OA",
  get: ({ get }) => {
    const warehouse = get(warehouseAtom);
    if (warehouse) {
      const result = {
        oaId: warehouse[0]?.company.oa_id,
        oaType: warehouse[0]?.company.type_oa,
        isActiveFollowOA: warehouse[0]?.company?.is_active_follow_oa,
      };
      return result;
    }
    return null;
  },
});
