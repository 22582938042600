import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import { Helmet } from "react-helmet";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import voLangIcon from "images/icons/volang.svg";
import heartIcon from "images/icons/hear.svg";
import phoneIcon from "images/icons/call.svg";
import userIcon from "images/icons/userIcon.svg";
import carIcon from "images/icons/car1.svg";
import listIcon from "images/icons/icon-park-outline_view-list.svg";

import { useUser } from "atom/user/useUser";

export interface AuthorPageProps {
  className?: string;
}

const homeData = [
  {
    icon: userIcon,
    title: "Thông tin người dùng",
    href: "information",
  },
  // {
  //   icon: carIcon,
  //   title: "Tin tức",
  //   href: "news",
  // },
  {
    icon: carIcon,
    title: "Xe đang thuê",
    href: "current-rental",
  },
  {
    icon: voLangIcon,
    title: "Xe của tôi",
    href: "rental-management",
  },
  {
    icon: heartIcon,
    title: "Xe yêu thích",
    href: "favorite-vehicles",
  },
  // {
  //   icon: addressIcon,
  //   title: "Sổ địa chỉ",
  //   href: "address-book",
  // },
  // {
  //   icon: listIcon,
  //   title: "Điều khoản & chính sách",
  //   href: "terms-and-policies",
  // },
  // {
  //   icon: phoneIcon,
  //   title: "Liên hệ và góp ý",
  //   href: "contact-and-feedback",
  // },
  // {
  //   icon: walletIcon,
  //   title: "Tiện ích",
  //   href: "utility",
  // },
];

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    user: { user },
  } = useUser();

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-md sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked={user?.ekyc_status !== "pending"}
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={user?.profile_image_zalo_url || user?.profile_image_url}
        />

        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{user?.name}</h2>
        </div>

        <div className="space-y-5 ">
          {homeData?.map((item) => (
            <button
              onClick={() => navigate(`/author/${item?.href}`)}
              key={item?.href}
              className="flex items-center space-x-2 min-w-[220px] relative"
            >
              {pathname?.includes(item?.href) && (
                <div className="w-1 rounded-full h-6 bg-[#8CC542] absolute left-0" />
              )}
              <img src={item?.icon} alt="" className="w-5" />
              <span className="text-neutral-600 dark:text-neutral-300">
                {item?.title}
              </span>
            </button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>3GO | Trang Cá Nhân</title>
        <meta
          name="description"
          content="Xem và chỉnh sửa thông tin cá nhân của bạn trên 3GO. Quản lý đặt xe, thông tin tài khoản và nhiều hơn nữa."
        />
        <meta
          name="keywords"
          content="trang cá nhân, thông tin tài khoản, quản lý đặt xe, 3GO"
        />
        <meta property="og:title" content="3GO | Trang Cá Nhân" />
        <meta
          property="og:description"
          content="Xem và chỉnh sửa thông tin cá nhân của bạn trên 3GO. Quản lý đặt xe, thông tin tài khoản và nhiều hơn nữa."
        />
        <meta
          property="og:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <meta property="og:url" content="https://3go.ui.aimini.vn/profile" />
        <meta property="og:type" content="profile" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="3GO | Trang Cá Nhân" />
        <meta
          name="twitter:description"
          content="Xem và chỉnh sửa thông tin cá nhân của bạn trên 3GO. Quản lý đặt xe, thông tin tài khoản và nhiều hơn nữa."
        />
        <meta
          name="twitter:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Person",
              name: "Người Dùng",
              url: "https://3go.ui.aimini.vn/profile",
              image:
                "https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png",
            }),
          }}
        />
      </Helmet>

      <main className="container md:mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="hidden md:block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-4/6 xl:w-3/4 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {/* {renderSection1()} */}
          {/* {renderSection2()} */}
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default AuthorPage;
