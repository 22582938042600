import React from "react";
import {
  CarVerticalIcon,
  FuelEfficiencyIcon,
  FuelIcon,
  SeatsIcon,
  TransmissionIcon,
} from "images/svg";

const Characteristic = (data) => {
  return (
    <div className="flex mt-5 flex-row items-center gap-5 justify-around flex-wrap">
      <div className="flex flex-col items-center skew-y-1 min-w-[100px]">
        <SeatsIcon className="w-[36px] h-[36px] text-[#868A8D]" />
        <span className="text-base mt-1 font-normal text-textPrimary dark:text-neutral-200">
          Số ghế
        </span>
        <span className="text-sm font-normal text-textSecondary dark:text-neutral-400">
          {data?.car?.seats} chỗ
        </span>
      </div>
      <div className="flex flex-col items-center skew-y-1 min-w-[100px]">
        <FuelIcon className="w-[36px] h-[36px] text-[#868A8D]" />
        <span className="text-base mt-1 font-normal text-textPrimary dark:text-neutral-200">
          Nhiên liệu
        </span>
        <span className="text-sm font-normal text-textSecondary">
          {data?.car?.fuel_type}
        </span>
      </div>
      <div className="flex flex-col items-center skew-y-1 min-w-[100px]">
        <TransmissionIcon className="w-[36px] h-[36px] text-[#868A8D]" />
        <span className="text-base mt-1 font-normal text-textPrimary dark:text-neutral-200">
          Truyền động
        </span>
        <span className="text-sm font-normal text-textSecondary">
          {data?.car?.transmission === "automatic" ? "Số tự động" : "Số sàn"}
        </span>
      </div>
      <div className="flex flex-col items-center skew-y-1 min-w-[100px]">
        <CarVerticalIcon className="w-[36px] h-[36px] text-[#868A8D]" />
        <span className="text-base mt-1 font-normal text-textPrimary dark:text-neutral-200">
          Màu sắc
        </span>
        <span className="text-sm font-normal text-textSecondary">
          {data?.car?.color}
        </span>
      </div>
      <div className="flex flex-col items-center skew-y-1 min-w-[100px]">
        <FuelEfficiencyIcon className="w-[36px] h-[36px] text-[#868A8D]" />
        <span className="text-base mt-1 font-normal text-textPrimary dark:text-neutral-200">
          NL tiêu hao
        </span>
        <span className="text-sm font-normal text-textSecondary">
          {data?.car?.fuel_consumption}
          {/* L/100km */}
        </span>
      </div>
    </div>
  );
};

export default Characteristic;
