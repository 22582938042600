import { useAsyncEffect, useRequest } from "ahooks";
import { useRecoilState } from "recoil";
import { useWarehouse } from "atom/warehouse/useWarehouse";
import { getBannersApi } from "services/api/bannerApi";
import { BannerProps, bannerAtom } from "./banner";

export const useBanner = () => {
  const { warehouseId } = useWarehouse();
  const [banner, setBanner] = useRecoilState<BannerProps>(bannerAtom);

  const requestGetBanner = useRequest(
    () => getBannersApi(warehouseId as string),
    {
      cacheKey: "get-banner",
      manual: true,
      onSuccess: (res: any) =>
        setBanner({ data: res?.data?.banners, isCalledApi: true }),
      onError: (err: any) => console.log("API BANNER", err?.message),
    }
  );

  useAsyncEffect(async () => {
    if (warehouseId && !banner.isCalledApi) {
      requestGetBanner.runAsync();
    }
  }, [warehouseId]);

  return {
    banner,
    requestGetBanner,
    setBanner,
  };
};
