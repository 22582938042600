import React from "react";
import { AddTimeIcon, InfoIcon, LockIcon } from "images/svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const FooterCurrentRental = ({ setModal }) => {
  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-600 z-40 min-h-[60px]">
      <div className="flex flex-row items-center justify-around mt-3 mb-5">
        <button className="flex flex-col items-center space-y-1">
          <AddTimeIcon className="w-8 h-8" />
          <span className="text-textSecondary text-sm font-semibold">
            Thuê thêm
          </span>
        </button>
        <button className="flex flex-col items-center space-y-1">
          <LockIcon className="w-8 h-8" />
          <span className="text-textSecondary text-sm font-semibold">
            Khởi động xe
          </span>
        </button>
        <button className="flex flex-col items-center space-y-1">
          <InfoIcon className="w-8 h-8" />
          <span className="text-textSecondary text-sm font-semibold">
            Xem chi tiết
          </span>
        </button>
      </div>
      <div className="container flex items-center justify-between font-semibold">
        <button
          className="text-textSecondary"
          onClick={() =>
            setModal((prev) => ({
              ...prev,
              cancel: true,
            }))
          }
        >
          Hủy chuyến
        </button>
        <ButtonPrimary>Liên hệ hỗ trợ</ButtonPrimary>
      </div>
    </div>
  );
};

export default FooterCurrentRental;
