import React, { FC } from "react";
import LocationInput from "../LocationInput";
import ExperiencesDateSingleInput from "./ExperiencesDateSingleInput";
import SearchButton from "../SearchButton";

export interface ExperiencesSearchFormProps {
  readOnly?: boolean | string;
}

const ExperiencesSearchForm: FC<ExperiencesSearchFormProps> = ({
  readOnly,
}) => {
  const renderForm = () => {
    return (
      <form className="w-full relative flex flex-col md:flex-row  rounded-3xl md:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <LocationInput
          className="flex-[1]"
          placeHolder={
            typeof readOnly === "string" ? "Địa điểm hiển tại" : "Địa chỉ"
          }
          desc={typeof readOnly === "string" ? readOnly : "Bạn muốn đi đâu?"}
          readOnly={readOnly}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <ExperiencesDateSingleInput className="flex-1 " start />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <ExperiencesDateSingleInput
          title="Ngày kết thúc"
          className="flex-[0.8]"
        />
        {/* <GuestsInput
          className="flex-1"
          buttonSubmitHref="/listing-experiences"
        /> */}
        <SearchButton className="flex-[0.5]" />
      </form>
    );
  };

  return renderForm();
};

export default ExperiencesSearchForm;
