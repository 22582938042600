import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  error?: string;
  icon?: any;
  disabled?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-2xl",
      children,
      type = "text",
      error,
      icon,
      disabled,
      ...args
    },
    ref
  ) => {
    return (
      <>
        <div className="relative">
          <input
            disabled={disabled}
            ref={ref}
            type={type}
            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className} ${
              disabled && "text-neutral-500 dark:text-gray-400"
            }`}
            {...args}
          />
          {icon && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-3 z-20">
              {icon}
            </span>
          )}
        </div>
        {error && (
          <p className=" text-red-500 text-xs mt-1 font-normal">{error}</p>
        )}
      </>
    );
  }
);

export default Input;
